import React from "react";
import styled, { css } from "styled-components";
import colors from "./utils/colors.json";
import { Link } from "react-router-dom";

const Container = styled(Link)`
  color: #fff;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
  padding-top: 15px;
  box-sizing: border-box;
  text-transform: uppercase;

  svg {
    height: 1.2em;
    width: 1.2em;
    path {
      fill: #fff;
    }
  }

  ${(props) =>
    props.editable
      ? css`
          color: ${colors.primary_green};
        `
      : css`
          color: ${colors.primary_white};
          border-top: 0.05em solid #ffffff44;
        `}
`;

const NNLink = ({ ...props }) => {
  var icons = {
    plus: (
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.56667 11V0H6.43333V11H4.56667ZM0 6.43333V4.56667H11V6.43333H0Z"
          fill="white"
        />
      </svg>
    ),
    edit: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.98 36.2">
        <path
          d="M-743.18,868.56a3.38,3.38,0,0,0-3.27-2.53,3.37,3.37,0,0,0-3.38,3.37,3.38,3.38,0,0,0,2.53,3.27v29.56h1.7V872.67a3.39,3.39,0,0,0,2.42-2.42h28.33v-1.69Z"
          transform="translate(749.83 -866.03)"
          fill="#000"
        />
        <path
          d="M-729.92,899.83l2.72-9.52-1.74-1.74h0a1.12,1.12,0,0,1-.28-1.06,1.09,1.09,0,0,1,.77-.77,1.12,1.12,0,0,1,1.06.28l1.81,1.82,9.45-2-23-10Z"
          transform="translate(749.83 -866.03)"
          fill="#000"
        />
      </svg>
    ),
  };
  return (
    <Container {...props}>
      {icons[props.icon]} {props.label}
    </Container>
  );
};

export default NNLink;
