import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ProjectPage from './pages/ProjectPage'
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from "react-router-dom";
import NoiseNeutralTool from './pages/Tool/NoiseNeutralTool';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorBoundary />
  },
  {
    path: "/calculate",
    element: <NoiseNeutralTool />,
    errorElement: <ErrorBoundary />

  }, {
    path: "/project/:id",
    element: <ProjectPage />,
    errorElement: <ErrorBoundary />

  }
]);

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <div>{error}</div>;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);
