import React, { Component, useState, useRef } from "react";
import styled from "styled-components";
import colors from './utils/colors.json'
import cms from './utils/copywriting.json'
import useLanguageStore from "./useLanguageStore";
import Swiper from 'react-id-swiper';
import 'swiper/swiper.min.css';
import { useNavigate } from "react-router-dom";

const Container = styled.div`
    position: relative;
    width: 100%;
    /* min-height: ${window.innerHeight + 'px'}; */
    min-height: 100vh;
    box-sizing: border-box;
    padding: 80px 0;
    overflow-x: hidden;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${colors.quarter_black};
    &::-webkit-scrollbar {
        display: none;
    }


    .galery-scrooler{
        width: 100%;
        display: block;
        overflow-x: scroll;
        &::-webkit-scrollbar {
        display: none;
    }

    }

    .galery-title{
        display: flex;
        align-items: flex-start;
        justify-content: start;
        width: 100%;
    }
    
    .galery-description{
        font-family: 'Moderat-Mono-Regular';
        line-height: 22px;
        color: ${colors.primary_gray};
        font-size: clamp(1rem, 1.0vw, 0.8rem);
        margin: 0 20px;
        max-width: 500px;
    }

    .problem-number{
        margin-top: 0 !important;
        padding-left: 30px;
    }

    .galery-wrapper{
        display: flex;
        height: auto;
        min-width: 100%;
        overflow-x: scroll;
        scroll-behavior: smooth;
        align-items: flex-start;
        justify-content: start;
        width: fit-content;    
        
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .arrows{      
        position: absolute;
        display: none;
        left: 0;
        right: 0;
        transform: translateY(750%);
        /* margin-top: 50%; */
        height: 30px;
        width: 100%;
        justify-content: space-between;
        z-index: 11;

        img{
            width: 40px;
            cursor: pointer;
            margin-left:-15px;
            margin-right:-15px;
            z-index: 10;
        }
    }

    .swiper-slide-active .card-wrapper .arrows{
        display: flex;
    }

    .swiper-slide-active{
        /* transform: scale(1.2); */
        box-sizing: border-box;
        z-index: 10;
        /* border: 10px solid transparent; */
    }

    #arrow-left{
        transform: rotateZ(180deg);
    }  
 
    @media screen and (max-width: 600px) {
        padding: 0px;

        .galery-wrapper{
            margin-left: 20px;
        }

        .galery-description{
            margin-bottom: 15px;
        }

        .galery-title{
            padding: 20px 10px;
            padding-bottom: 0;
            box-sizing: border-box;
            width: 100%;
        }
    
        .swiper-slide-active{
            transform: scale(.9);
            box-sizing: border-box;
        }

        .slick-center .card-container{
            padding: 0 10px;
        }

        .card-wrapper{
            margin: 0 !important;
        }

        .arrows{
            /* margin-top: 50%; */
            height: 30px;
            width: 100%;
            justify-content: space-between;
            z-index: 10;

            img{
                margin-left:-10px;
                margin-right:-10px;
            }
        }
    }
`

const Card = styled.div`
    padding: 10px;
    padding-bottom: 0;
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;
    border-color: transparent;

    .card-wrapper{
        background-color: ${colors.secondary_black};
        border-radius: 30px;
        color: ${colors.primary_gray};
        box-sizing: border-box;
        padding: 5px 25px;
        padding-bottom: 25px;
        margin-top: 30px;
        height: 500px;
        width: 300px;
        /* overflow: hidden; */
    }
   
    .card-top{
        font-family: "Anybody-Regular";
        display: flex;
        justify-content: space-between;
        color: ${colors.primary_green};
        margin-bottom: 0px;
        font-size: 12px;
        height: 5%;
    }

    .border{
        border-radius: 15px 15px 0 0 !important;
        border-bottom: 0 !important;
        letter-spacing: 1.5px;
        height: 200px;
    }

    .project-content{
        width: 100%;  
        min-height: 430px;
        border: .1px solid ${colors.secondary_gray};
        padding: 10px;  
        border-radius: 15px;
        box-sizing: border-box;
        cursor: pointer;
        overflow: hidden;
        /* max-height: 300px; */

        p{
            margin: 0;
        }
    }

    .project-content1{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: auto;
        box-sizing: border-box;
        padding: 10px;

        .project-author{
            padding: 0;
            color: ${colors.primary_green} !important;
        }
    }

    .project-cover{
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 180px;
        border-radius: 10px;
        margin-bottom: 20px;
    }
    
    .title{
        font-family: 'NBLIGHTRMS';
    }

    .paragraph{
        font-family: 'Anybody-Regular';
        font-size: clamp(.6rem, .8vw, .8rem);
        line-height: 1.5em;
        box-sizing: border-box;

        padding: 5px;
    }

    .ni{
        color: ${colors.primary_green};
        padding: 5px;
        font-size: clamp(.8rem, 1.2vw, 1.5rem);
    }

    .project-title{        
        font-size: 18px;
    }

    .open-card{
        width: 30px;
        height: 30px;
    }
    @media screen and (min-width: 1500px) {
        .card-wrapper{
            width: 350px;
            height: 600px;
        }

        .project-content{
            min-height: 92%;
        }

        .project-cover{
            height: 250px;
        }
    }
    
    @media screen and (max-width: 600px) {
        min-width: 320px !important;
        transform: none;
        padding: 5px;
        box-sizing: border-box;
        /* height: auto !important; */

        .project-cover{
            min-height: 220px;
        }

        .project-content{
            height: 90%;
        }

        .card-wrapper{
            width: 100% !important;
            /* height: auto; */
            height: 600px;
            padding: 10px 25px;
            padding-bottom: 25px;
            border-radius: 15px;
        }

        .card-top{
            margin-bottom: 10px;
        }

        .project-text{
            height: 50px !important;
        }

        .project-title{        
            font-size: 1.3em;
        }

        .ni{
            font-size: 1.4em;
        }

        .paragraph{
            font-size: .8em;
            line-height: 1.5em;
            box-sizing: border-box;
            /* height: 180px !important; */
        }
    }
    
`

const Projects = (props) => {
    var lang = useLanguageStore((state) => state.language);
    const ref = useRef(null);
    const navigate = useNavigate();

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const containerRef = React.useRef(null);

    const handleShowProject = (e) => {
        navigate(`/project/${e.target.id}`)
    }

    const handleMouseDown = (event) => {
        setIsDragging(true);
        setStartX(event.pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
    };

    const handleMouseMove = (event) => {
        if (!isDragging) return;
        event.preventDefault();
        const x = event.pageX - containerRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        containerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleScrollHorizontally = (event) => {
        const element = event.target;
        if (element.scrollWidth > element.clientWidth) {
            element.scrollLeft += event.deltaY;
            event.preventDefault();
        }
    }


    const galeryItems = (lang) => {
        var array = [];
        cms[lang].galery.items.forEach((item, key, index) => {
            key = key + 1;
            array.push(<Card key={key} className="card-container">
                <div className="card-wrapper">
                    <p className="card-top">
                        <span className="top-title">{lang == 'pt'? 'PROJETO' : 'PROJECT'}</span>
                        <span className="top-number"> 0{key}</span>
                    </p>

                    <div className="arrows">
                        {/* <img onClick={goPrev} id="arrow-left" src="/assets/img/arrow-slider.svg" alt="" />
                        <img onClick={goNext} id="arrow-right" src="/assets/img/arrow-slider.svg" alt="" /> */}
                    </div>

                    <div className="project-content" id={key - 1} onClick={handleShowProject}>
                        <div className="project-cover" id={key - 1} style={{ backgroundImage: `url(${item.item_cover})` }} alt="" />
                        <div className="project-content1" id={key - 1} >
                            <p className="project-text" id={key - 1} >
                                <span id={key - 1} className="project-title title">{item.item_title}</span> <br />
                                <span id={key - 1} className="project-author paragraph">{item.item_author}</span>
                            </p>
                            {/* <img id={key - 1} className="open-card" src="/assets/img/plus.png" alt="" /> */}
                        </div>
                        <p className="title ni">-{item.reduction_level} NI</p>
                        <br />
                        <p className="paragraph">{item.item_subtitle}</p>
                    </div>
                </div>
            </Card>)
        })
        return array;
    }

    return (
        <Container id="galery">
            <div className="galery-title">
                <div className="problem-number">02</div>
                <p className="galery-description">{cms[lang].galery.subtitle}</p>
            </div>

            <div className="galery-scrooler"
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                onWheel={handleScrollHorizontally}
            >
                <div className="galery-wrapper">
                    {galeryItems(lang)}
                </div>
            </div>
        </Container >
    );

}

export default Projects;