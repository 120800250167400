import densidadeData from '../../data/ibge.json'

const getDensidade = (e, m, b) => {
    var densidade;
    for (let i = 0; i < densidadeData.data.length; i++) {
        if (e === densidadeData.data[i].estado) {
            if (m !== '') {
                for (let a = 0; a < densidadeData.data[i].dados.length; a++) {
                    if (densidadeData.data[i].dados[a].item === m) {
                        densidade = densidadeData.data[i].dados[a].densidade_demografica;
                    }
                }
            }
            if (m == "São Paulo") {
                for (let c = 0; c < densidadeData.data[i].dados.length; c++) {
                    if (densidadeData.data[i].dados[c].item == "São Paulo") {
                        for (let d = 0; d < densidadeData.data[i].dados[c].bairros.length; d++) {
                            if (densidadeData.data[i].dados[c].bairros[d].bairro == b) {
                                densidade = densidadeData.data[i].dados[c].bairros[d].densidade_demografica;
                            }
                        }
                    }
                }
            }
            if (m == '') {
                densidade = densidadeData.data[i].dados[0].densidade_demografica;
            }
        }
    }
    return Number(densidade / 1000000);
}

const getBuildingArea = (dataset) => {
    var buildingLatLngs = []

    for (let i = 0; i < dataset.length; i++) {
        var objLatLng = {};
        objLatLng.lat = dataset[i][0];
        objLatLng.lng = dataset[i][1];
        buildingLatLngs.push(objLatLng);
    }

    return window.google.maps.geometry.spherical.computeArea(buildingLatLngs)
}

const get_direction = function (vector, gps) {
    if (vector[1] <= gps.y) {
        if (vector[0] > gps.x) {
            return 1;
        } else {
            return 4;
        }
    }
    if (vector[1] > gps.y) {
        if (vector[0] > gps.x) {
            return 2;
        } else {
            return 3;
        }
    }
}

const get_step = function (hit_step, vector, start, step, direction) {

    var vector_t = [];

    if (direction == 1) {
        vector_t[0] = start[0] + (((vector[0] - start[0]) / hit_step) * step);
        vector_t[1] = start[1] - (((start[1] - vector[1]) / hit_step) * step);
    }
    if (direction == 2) {
        vector_t[0] = start[0] + (((vector[0] - start[0]) / hit_step) * step);
        vector_t[1] = start[1] + (((vector[1] - start[1]) / hit_step) * step);
    }
    if (direction == 3) {
        vector_t[0] = start[0] - (((start[0] - vector[0]) / hit_step) * step);
        vector_t[1] = start[1] + (((vector[1] - start[1]) / hit_step) * step);
    }
    if (direction == 4) {
        vector_t[0] = start[0] - (((start[0] - vector[0]) / hit_step) * step);
        vector_t[1] = start[1] - (((start[1] - vector[1]) / hit_step) * step);
    }
    return vector_t;
}



export { getDensidade, getBuildingArea, get_direction, get_step }