import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Cover from "../components/Cover";
import colors from "../components/utils/colors.json";
import TheProblem from "../components/TheProblem";
import Projects from "../components/Projects";
import NavBar from "../components/NavBar";
import useLanguageStore from "../components/useLanguageStore";
import SendYourProject from "../components/SendYourProject";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import Explanation from "../components/Explanation";
import changeSiteLanguage from "../components/utils/changeSiteLanguage";

const HomeContainer = styled.div`
  width: 100vw;
  font-family: "Inter";
  display: flex;
  flex-direction: column;
  align-items: center;

  .problem-number {
    font-family: "NBLIGHTRMS";
    color: ${colors.primary_green};
    font-size: clamp(0.8rem, 2.5vw, 2.5rem);
    align-self: flex-start;
    width: 50px;
    padding-left: 30px;
  }

  @media screen and (max-width: 600px) {
    overflow: hidden;
    .problem-number {
      display: none;
    }
  }
`;

const Home = () => {
  ReactGA.initialize("G-5BHWMWWD4G");
  ReactGA.pageview(window.location.pathname + window.location.search);
  var lang = useLanguageStore((state) => state.language);

  const [menuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  const handleMenu = (link) => {
    if (link[0] === "#") {
      const element = document.querySelector(`${link}`);
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate(link);
    }
    setMenuVisible(false);
  };

  useEffect(() => {
    changeSiteLanguage();
    handleMenu(window.location.hash);
  }, []);

  return (
    <HomeContainer>
      <NavBar
        handleMenu={handleMenu}
        type={"home"}
        className={menuVisible ? "visible" : ""}
        lang={lang}
        setMenuVisible={setMenuVisible}
      />
      <Cover
        handleMenu={handleMenu}
        lang={lang}
        menuVisible={menuVisible}
        setMenuVisible={setMenuVisible}
        header={true}
      />
      <TheProblem ReactGA={ReactGA} />
      <Projects />
      <Explanation lang={lang} />
      <SendYourProject />
      {/* <Terms /> */}
    </HomeContainer>
  );
};
export default Home;
