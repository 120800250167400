import './App.css';
import Home from './pages/Home';
import { Router } from 'react-router';



function App() {
  return (
    <Home />
  );
}

export default App;
