import styled, { css } from "styled-components";
import colors from "../../components/utils/colors.json";

const Controls = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  box-sizing: border-box;
  padding: 30px;
  padding-top: 0;
  height: 100%;
  transition: all 0.5s ease-in-out;
  box-sizing: border-box;

  .custom-info {
    font-size: 12px;
    border-top: 0.05em solid #ffffff44;
    display: block;

  padding-top: 15px;
  }

  .hr-line {
    margin-top: 20px;
    border-bottom: 0.1px solid rgba(255, 255, 255, 0.114) !important;
    width: 100%;
    display: ${(props) => (props.isControlVisible ? `block` : `none`)};
  }

  .active_second_step {
    height: ${(props) =>
      props.isControlVisible ? `revert` : `0px !important`};
    margin: ${(props) =>
      props.isControlVisible ? `revert` : `0px !important`};
    overflow: ${(props) => (props.isControlVisible ? `visible` : `hidden`)};
    transition: all 0.5s ease;
  }

  @media screen and (max-width: 600px) {
    .back-arrow {
      position: relative !important;
      /* top: ${(props) =>
        props.cardsNI ? "-50% !important" : "10px !important"}; */
      top: 0 !important;
      right: 0 !important;
      margin-bottom: 10px;
      width: 25px;
      height: 25px;
      z-index: 5;
    }
  }

  .back-arrow {
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;
  }

  @media screen and (min-width: 1500px) {
    .card {
      width: 400px !important;
    }
  }

  .card {
    position: relative;
    width: 320px;
    min-height: ${(props) => (props.isControlVisible ? `500px` : `0`)};
    max-height: 600px;
    background-color: ${colors.secondary_black};
    border-radius: 20px;
    color: ${colors.primary_white};
    box-sizing: border-box;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    transition: all 0.5s ease;
  }

  @media screen and (min-width: 1500px) {
    .card {
      width: 350px;
      min-height: ${(props) => (props.isControlVisible ? `600px` : `0`)};
    }
  }

  .card-noise {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.5s ease;

    ${(props) =>
      props.isConfigVisible
        ? `
            opacity: 0;
            height: 0;
            padding: 0 !important;
            border: none !important;  
        `
        : `
            height: 65%;
            opacity: 1;
        `}
  }

  .card-stage {
    position: relative;
    overflow: visible;
    width: 250px;
    height: 250px;

    .mancha {
    }
  }

  .card-noise-bg {
    position: absolute;
    background-image: url("/assets/img/graph-escala.png");
    background-size: 100% 100%;
    width: 101%;
    height: 101%;
  }

  .card-group {
    transform-origin: 0px 0px;
  }

  .card-noise,
  .result {
    border: 0.1px solid ${colors.secondary_gray};
    padding: 15px 20px;
    border-radius: 15px;
    box-sizing: border-box;
  }

  .visible {
    height: auto !important;
    opacity: 1 !important;
    transition: all 1s ease-in-out;
  }

  .result {
    width: 100%;
    font-family: "NBLIGHTRMS";
    height: auto;
    overflow: hidden;
    height: 0;
    opacity: 0;

    p {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0px;
    }

    .rastro {
      width: 110px;
      margin-left: 10px;
    }
  }

  .ni-result {
    font-size: clamp(2.5rem, 2.5vw, 2.5rem);
    letter-spacing: 1px;

    div {
      margin-top: 5px;
    }
  }
  .asterisco {
    margin-bottom: auto;
  }

  .ni-unit {
    color: ${colors.primary_green};
    border: 1px solid ${colors.primary_green};
    border-radius: 5px;
    padding: 2px 10px;
    box-sizing: border-box;
    font-family: "Moderat-Mono-Regular";
    /* font-size: clamp(1rem, 1.5vw, 2.0rem); */
    font-size: clamp(1.8rem, 2.5vw, 2.5rem);
    letter-spacing: 2px;
    margin-left: 5px;
  }

  .number {
    font-family: "Moderat-Mono-Regular";
  }

  .building-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    text-transform: unset;

    p {
      margin: 5px 0;
      font-size: 0.8rem;
    }
  }

  .building-item:nth-child(2) {
    margin-bottom: 15px;
  }

  .building-info {
    flex-direction: column;
    font-family: "Anybody-Regular";
    letter-spacing: 2px;
    color: ${colors.primary_gray};
    flex-direction: column;
    justify-content: space-between;
    height: ${(props) => (props.isConfigVisible ? `100%` : `0`)};
    opacity: ${(props) => (props.isConfigVisible ? `1` : `0`)};
    display: ${(props) => (props.isConfigVisible ? `flex` : `none`)};
    overflow: hidden;
    transition: all 0.5s ease;

    .instruction {
      text-transform: uppercase;
      line-height: 1.3em;
      font-size: clamp(0.8rem, 0.8vw, 1rem);
      /* margin-top: 5px; */
    }

    label {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  #address {
    width: 100%;
    background-color: ${colors.secondary_gray};
    border: none;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 5px;
    color: ${colors.primary_green};
    font-family: "Anybody-Regular";
    letter-spacing: 1px;
    outline: none;
  }

  #address:focus {
    border: 1px solid ${colors.primary_green};
    outline: none;
  }

  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    background-color: ${colors.primary_green};
    border-radius: 10px;
    outline: none;
    margin: 10px 0;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background-color: ${colors.primary_green};
    border-radius: 50%;
    cursor: pointer;
  }

  input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: ${colors.primary_green};
    border-radius: 50%;
    cursor: pointer;
  }

  input[type="range"]::-ms-thumb {
    width: 20px;
    height: 20px;
    background-color: ${colors.primary_green};
    border-radius: 50%;
    cursor: pointer;
  }

  .ok-btn {
    color: ${colors.primary_green};
    text-decoration: none;
    cursor: pointer;
  }
  .ok-btn:active {
    transform: scale(0.9);
  }

  .base-number {
    display: ${(props) => (props.cardsNI ? "block" : "none")};
    width: 100%;
    text-align: end;
    font-size: 12px;
    font-family: "Moderat-Mono-Regular";
    color: ${colors.primary_gray};
  }

  @media screen and (max-width: 600px) {
    background-color: ${(props) =>
      props.cardsNI ? "transparent" : `${colors.secondary_black}`};
    background-image: none;
    /* height: ${(props) => (props.cardsNI ? "auto !important" : "auto")}; */
    overflow-y: ${(props) => (props.cardsNI ? "visible" : "scroll")};
    /* padding: 30px; */
    padding: ${(props) => (props.cardStage ? "5px 25px 10px 25px" : "20px")};
    flex-direction: column;
    width: 100%;
    justify-content: start;

    .building-item {
      margin-bottom: 5px;
    }

    ${(props) =>
      props.cardsNI
        ? `
            height: auto;

            .card{
                padding: 10px 20px !important;
            }
        `
        : ``}

    .desktop {
      display: none;
    }

    .result {
      /* width: fit-content !important;   */
      margin: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 15px 10px;
      box-sizing: border-box;

      .ni {
        margin-left: auto;
        margin-right: auto;
      }

      p {
        /* flex-direction: column; */
        /* align-items: flex-start; */
      }

      .rastro {
        width: 50%;
        margin-left: 10px;
        /* margin-top: 10px; */
      }
    }

    .card {
      width: 100%;
      padding: 0px;
      background: none;
      min-height: ${(props) => (props.cardsNI ? "0" : "initial")};
      height: ${(props) => (props.cardsNI ? "auto !important" : "auto")};
      justify-content: center !important;
      /* margin-top: 25px; */
      align-items: center !important;
      background-color: ${colors.secondary_black};

      br {
        display: none;
      }

      .instruction {
        margin: 10px 0px;
        letter-spacing: 1px;
      }

      input {
        margin-bottom: 20px;
      }

      .building-info {
        justify-content: center;
        gap: 5px;
        overflow: visible;
        height: 100%;
      }

      .nn-btn {
        margin-top: auto;
      }
    }

    #address {
      margin: 0;
    }

    .base-number {
      font-size: 10px;
    }

    .card-stage {
      width: 50px;
      height: 50px;
      margin-left: 15px;
      display: ${(props) =>
        props.cardStage ? "block !important" : "none !important"};
      /* border: 1px solid red; */
    }
  }
`;

const LoadingScreen = styled.div`
  position: absolute;
  top: 0;
  background-color: ${colors.primary_black};
  background-image: url("/assets/img/backgrouns-gradient.png");
  background-size: cover;
  width: 101%;
  height: 101%;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: -5;
  transition: all 0.5s ease-in-out;

  .loading-text {
    color: ${colors.primary_green};
    font-size: 14px;
    font-family: "Anybody-Medium";
    text-transform: uppercase;
  }
`;

export { Controls, LoadingScreen };
