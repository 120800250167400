import useLanguageStore from "../useLanguageStore";

const changeSiteLanguage = () => {

    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
    }

    if (window.location.host == "ruidoneutro.com.br") {
        useLanguageStore.setState({ language: 'pt' })
        document.title = 'Ruído Neutro'
        link.href = process.env.REACT_APP_SERVER_URL + '/pt-favicon.ico';
    }

    if (window.location.host == "noiseneutral.com") {
        useLanguageStore.setState({ language: 'en' })
        document.title = 'Noise Neutral'        
        link.href = process.env.REACT_APP_SERVER_URL + '/en-favicon.ico';
    }
}
export default changeSiteLanguage;