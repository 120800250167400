import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import cms from '../components/utils/copywriting.json'
import colors from '../components/utils/colors.json'
import useLanguageStore from "../components/useLanguageStore";
import NavBar from "../components/NavBar";
import Swiper from 'react-id-swiper';
import 'swiper/swiper.min.css';
import ReactGA from 'react-ga';


const Container = styled.div`
    position: relative;
    width: 100%;
    min-height: ${props => props.windowH + 'px'};
    box-sizing: border-box;
    padding: 50px;
    overflow-x: hidden;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${colors.terciary_black};
    background-image: ${props => `url(${props.bgSource})`};
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    .project-img-mobile{
        display: none;
    }

    .project-content{
        /* width: 100%; */
        display: flex;
        flex-direction: row;
        gap: 30vw;
        margin-top: 40px;
    }

    .project-header{
        position: relative;
        width: 350px;
        height: 350px;
        padding: 15px;
        box-sizing: border-box;
        color: ${colors.primary_white};
        background-image: url("/assets/img/malha.png");
        background-position: center;
        background-size: cover;

        .project-index{
            font-family: 'Moderat-Mono-Regular';
            font-size: 13px;
        }

        img{
            width: 60px; 
            height: 60px; 
        }
    }

    .title{
        font-family: 'Moderat-Mono-Regular';
        font-size: clamp(1.5rem, 2vw, 2.8rem);
    }

    .project-text{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;

    }

    .project-author {
        font-family: 'Moderat-Mono-Regular';
        background-color: ${colors.primary_green};
        color: ${colors.secondary_black};
        font-size: clamp(0.9rem, 0.8vw, 2.5rem);
        /* margin: 10px 0; */
        padding: 1px 5px;
        /* box-sizing:border-box; */
    }

    .ni{
        font-family: 'NBLIGHTRMS';
        color: ${colors.secondary_white};
        font-size: clamp(2rem, 3.0vw, 2.5rem);
        width: 100%;
        text-align: right;
    }

    .ni-icon{
        background-color: ${colors.secondary_white};
        color: ${colors.secondary_black};
        font-size: clamp(2rem, 2.5vw, 2.5rem);
        padding: 8px;
        border-radius: 5px;
        text-shadow: .5px 0 ${colors.secondary_black}, -.5px 0 ${colors.secondary_black}, 0 .5px ${colors.secondary_black}, 0 -.5px ${colors.secondary_black},
               .5px .5px ${colors.secondary_black}, -.5px -.5px ${colors.secondary_black}, .5px -.5px ${colors.secondary_black}, -.5px .5px ${colors.secondary_black};
    }

    .project-infos{
        box-shadow: 0 0 18px 15px #00040c73;
        background-color: #00040c73;
        font-family: 'Anybody-Regular';
        font-size: clamp(0.5rem, 0.70vw, 0.7rem);
        line-height: 1.8em;
        color: ${colors.secondary_white};
        border-radius: 20px;
        overflow: hidden;
        width: 60%;
        max-width: 14rem;
        margin-left: 50px;
        box-sizing: border-box;
        padding: 20px;
        box-sizing: border-box;
        
        .infos{
            margin-bottom: 3rem;
        }
    }

    .project-carroussel{
        width: 100%;
        /* overflow-x: hidden; */
    }

    @media screen and (max-width: 600px) {
        padding: 20px 15px !important;
        justify-content: start;
        background-image: none;

        .ni{
            margin-bottom: 0;
        }

        .project-content{
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 0;
        }

        .project-header{
            height: fit-content;
            background: none;
            z-index: 1;

            .header-logo{
                display: none;
            }
        }

        .project-header,  .project-infos{
            width: 100%;
            max-width: unset;
        }
        .project-infos{
            padding: 0;
            margin: 0;
            font-size: 12px;
            transform: translateY(-10%);

            .infos{
                margin-bottom: 50px;
                margin-right: 100px;
                color: ${colors.primary_green}
            }
            .about{
                margin-left: 100px;
            }
        }

        .project-img-mobile{
            display: block;
            width: 180%;
            /* margin-left: -50%; */
            transform: translateX(-25%) translateY(-10%);
            z-index: 0;
        }
        
        .plus-menu{
            width: 100%;
            display: flex !important;
            flex-direction: row;
            justify-content: space-between;
            max-height: 30px;
            img{
                /* height: 100%; */
                width: 30px;
            }
        }
    }
`

const ImageItem = styled.div`
    max-width: 300px;
    height: 200px ;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    transition: all .5s ease;

    ${props => props.active ?
        `
        top: 0;
        transform: scale(2) !important;
        z-index: 3;
        ` :
        `
        transform: scale(1);
        z-index: 0;
        `}


    img{
        height: 100% ;
        width: auto;
    }

    @media screen and (max-width: 600px) {
        max-width: 300px;
        height: 180px ;
        margin-right: 10px;

        img{
            height: 100% ;
            width: auto;
        }
    }

`

const ProjectPage = (props) => {
    ReactGA.initialize('G-E4L2ZMKFGS');
    ReactGA.pageview(window.location.pathname + window.location.search);

    var lang = useLanguageStore((state) => state.language);
    let { id } = useParams();
    const ref = useRef(null);
    var item = cms[lang].galery.items[id];
    var navigate = useNavigate();

    const [windowH, setWindowH] = useState(window.innerHeight);
    const [windowW, setWindowW] = useState(window.innerWidth);
    const [activeIndex, setActiveIndex] = useState(null);
    const [menuVisible, setMenuVisible] = useState(false);


    window.addEventListener("resize", () => {
        setWindowH(window.innerHeight);
    })

    const handleMenu = (link) => {
        if (link[0] === "#") {
            navigate(`/${link}`);
        } else {
            navigate(link);
        }
        setMenuVisible(false);
    }

    const handleToggle = (e) => {
        setActiveIndex(e.target.id);
    }

    const carrousselItems = (lang) => {
        var array = [];
        cms[lang].galery.items[id].item_images.forEach((item, key) => {
            array.push(
                <ImageItem active={key == activeIndex} key={key} id={key} onClick={handleToggle} className="project-image">
                    <img src={item} id={key} alt="" />
                </ImageItem>
            )
        });
        return array;
    }

    const params = {
        // slidesPerView: 3,
        // loop: true,
        // loopedSlides: 4,
        centeredSlides: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        navigation: {
            nextEl: '#arrow-right',
            prevEl: '#arrow-left'
        },
        breakpoints: {
            700: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            1200: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            2000: {
                slidesPerView: 4,
                spaceBetween: 10
            },
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Container bgSource={item.item_images[0]} windowH={windowH} windowW={windowW}>
            <NavBar handleMenu={handleMenu} className={menuVisible ? 'visible' : ''} lang={lang} setMenuVisible={setMenuVisible} />
            <div className="mobile plus-menu">
                <img onClick={() => { navigate('/') }} src={cms[lang].navbar.logo} alt="" />
                {/* <img onClick={() => { setMenuVisible(true) }} src="/assets/img/plus.svg" alt="" /> */}
            </div>

            <div className="project-content">
                <div className="project-header">
                    <img className="header-logo" src={cms[lang].navbar.logo} alt="" />
                    <p className="project-index">{cms[lang] == 'pt' ? 'Projeto' : 'Project'} {Number(id) + 1}</p>
                    <p className="project-text">
                        <span className="project-title title">{item.item_title}</span>
                        <span className="project-author">{item.item_author}</span>
                    </p>
                    <p className="ni">-{item.reduction_level} <span className="ni-icon">NI</span></p>
                </div>

                <img className="project-img-mobile" src={item.item_images[0]} alt="" />

                <div className="project-infos">
                    <div className="infos">{item.item_infos.map((text) => {
                        return <p>{text}</p>
                    })}</div>
                    <div className="about">{item.item_paragraph.map((text) => {
                        return <p>{text}</p>
                    })}</div>
                </div>
            </div>

            {/* <div className="project-carroussel">
                <Swiper
                    ref={ref}
                    {...params}
                >
                    {carrousselItems(lang)}
                </Swiper>
            </div> */}
        </Container>
    )
}

export default ProjectPage;