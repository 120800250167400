import React from "react";
import styled, { css } from "styled-components";
import cms from './utils/copywriting.json'
import colors from './utils/colors.json'


const Container = styled.div`
    display: flex;
    padding: 100px;
    padding-top: 120px;
    padding-bottom: 50px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    min-height: 100vh;

    .section-title{
        font-size: clamp(1rem, 1.2vw, 1.2rem);
        font-family: 'Moderat-Mono-Regular';
        text-transform: uppercase;
        color: ${colors.secondary_white};
        /* margin-bottom: auto; */
        margin-bottom: 100px;
        margin-left: 200px;
    }

    .column-container{
        display: flex;
        width: 100%;
        justify-content: space-between;
    } 
    
    .section-number{
        font-family: 'NBLIGHTRMS';
        color: ${colors.primary_green};
        font-size: clamp(0.7rem, 2.2vw, 2.5rem);
    }  

    @media screen and (min-width:700px) and (max-width: 1500px) {
        padding: 50px;
        .section-title{
            margin-bottom: 50px;
            margin-left: 100px;
        }
    }

    @media screen and (max-width:700px) {
        padding: 20px;

        .column-container{
            flex-direction: column;
        }

        .section-title{
            margin-left: 0;
            margin-bottom: 30px;
            width: 200px;
            font-size: 20px;
        }
    }
`

const ContentColumn = styled.div`
    /* background-color: #383838; */
    width: 20%; 
    max-width: 400px;

    .content-title{
        margin-top: 0;
        font-family: 'Moderat-Mono-Regular';
        color: ${colors.secondary_white};
        text-transform: uppercase;
        font-size: clamp(1rem, 1.2vw, 1.2rem);
    }

    .content-paragraph{
        font-family: 'Anybody-Regular';
        letter-spacing: 2px;
        font-size: clamp(.7rem, 0.7vw, 0.8rem);
        color: ${colors.primary_gray};
        line-height: 1.8em;
    }

    .span-paragraph{
        display: block;
        margin-bottom: 20px;
    }

    @media screen and (min-width:700px) and (max-width: 1500px) {  
        max-width: 300px;
    }

    @media screen and (max-width:600px) {
        margin-bottom: 20px;
        width: 100%;
        max-width: 100%;


        .content-paragraph{
            letter-spacing: 1px;
            font-size: clamp(.8rem, 0.7vw, 0.8rem);
        }
    }
`

const Explanation = ({ lang }) => {


    return (
        <Container id="how">
            <p className="section-title">{cms[lang].about_noiseimpact.title}</p>

            <div className="column-container">
                <span className="section-number desktop">03</span>
                {cms[lang].about_noiseimpact.content.map((column, key) => {
                    return (
                        <ContentColumn key={key}>
                            <p className="content-title">{column.title}</p>
                            <p className="content-paragraph">{column.paragraph.map((paragraph, key) => {
                                return (<span key={key} className="span-paragraph"> {paragraph} </span>)
                            })}</p>
                        </ContentColumn>
                    )

                })}
            </div>

            <div className="arrowScroll" onClick={() => {
                const element = document.querySelector(`#submit`);
                element.scrollIntoView({ behavior: 'smooth' });
            }}> <img src="/assets/img/arrow-scroll.png" alt="" /> </div>
        </Container>
    )
}

export default Explanation;