import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import styled from "styled-components";
import colors from "./utils/colors.json"

const Container = styled.div`
    position: relative;
    height: ${props => props.height ? props.height + 'px' : '10px'};
    width: ${props => props.width ? props.width + 'px' : '70%'};
    margin: 5px 0;

    .paused{
        -webkit-animation-play-state:paused;
        -moz-animation-play-state:paused;
        -o-animation-play-state:paused; 
        animation-play-state:paused;
    }
`

const Bar = styled.div`
    position: absolute;
    height: 100%;
    left: 0;
    width: 0px;
    background-color: ${colors.primary_green};
    animation: progress ${props => props.duration? props.duration + "s": "4s" } linear normal ${props => props.timing};
    animation-delay: ${props => props.delay};

    @keyframes progress {
        0%{
            width: 0px;
            left: 0;
        }
        25%{
            width: 5px;  
        }
        50%{
            width: 0px;        
            /* left: 50%; */
        }
        70%{
            width: 5px;  
        }
        100%{
            width: 0px;
            left: 100%;
        }
    }
`

const BarComp = ({ delay, duration, timing }) => {

    const barRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            if (timing == 1) {
                barRef.current.classList.add("paused");
            }
        }, duration*750);
    }, [])

    return (
        <Bar ref={barRef} className="bar" timing={timing} duration={duration} delay={delay}></Bar>
    )
}

const MotionBars = ({ width, height, timing, duration, ...props }) => {

    return (
        <Container width={width} height={height}>
            <BarComp className="bar" duration={duration} timing={timing} delay={"0s"}></BarComp>
            <BarComp className="bar" duration={duration} timing={timing} delay={"1s"}></BarComp>
            <BarComp className="bar" duration={duration} timing={timing} delay={"0.8s"}></BarComp>
            <BarComp className="bar" duration={duration} timing={timing} delay={"1s"}></BarComp>
            <BarComp className="bar" duration={duration} timing={timing} delay={"1.8s"}></BarComp>
            <BarComp className="bar" duration={duration} timing={timing} delay={"2.2s"}></BarComp>
            <BarComp className="bar" duration={duration} timing={timing} delay={"2.8s"}></BarComp>
            <BarComp className="bar" duration={duration} timing={timing} delay={"3.0s"}></BarComp>
            <BarComp className="bar" duration={duration} timing={timing} delay={"2.8s"}></BarComp>
        </Container>
    )
}
export default MotionBars;