import { useState, useEffect } from "react";
import axios from "axios";
import colors from "../../components/utils/colors.json";
import cms from "../../components/utils/copywriting.json";
import NNButton from "../../components/NNButton";
import MotionBars from "../../components/MotionBars";
import NNLink from "../../components/NNLink";
import useLanguageStore from "../../components/useLanguageStore";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import ProjectsContainer from "../../components/ProjectsContainer";
import ReactGA from "react-ga";
import changeSiteLanguage from "../../components/utils/changeSiteLanguage";
import {
  getDensidade,
  getBuildingArea,
  get_direction,
  get_step,
} from "../../components/utils/toolUtils";
import { Controls, LoadingScreen } from "./style.jsx";

const NoiseNeutralTool = ({ result, setResult, prevResult, ...props }) => {
  window.divisor = 10000;

  ReactGA.initialize("G-5BHWMWWD4G");
  ReactGA.pageview(window.location.pathname + window.location.search);

  var lang = useLanguageStore((state) => state.language);
  var navigate = useNavigate();

  const [windowH, setWindowH] = useState(window.innerHeight);
  const [windowW, setWindowW] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWindowH(window.innerHeight);
    setWindowW(window.innerWidth);
  });

  var svgns = "http://www.w3.org/2000/svg";

  const [editable, setEditable] = useState(false);
  const [buildingPolygons, setBuildingPolygons] = useState([]);

  const [buildings, setBuildings] = useState([]);
  const [currentBuild, setCurrentBuild] = useState({ lat: 0, lng: 0 });
  const [densidade, setDensidade] = useState(0);

  const [cardsNI, setCardsNI] = useState(false);
  const [cardStage, setCardStage] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [resultVisible, setResultVisible] = useState(false);
  const [month, setMonth] = useState(24);
  const [meters, setMeters] = useState(500);

  const [monthLabel, setMonthLabel] = useState(24);
  const [metersLabel, setMetersLabel] = useState(500);

  const [searchFocus, setSearchFocus] = useState(false);
  const [isControlVisible, setIsControlVisible] = useState(false);

  const [isConfigVisible, setIsConfigVisible] = useState(true);
  const [address, setAddress] = useState("");
  var geocoder;
  var map;

  const [const_gps, setConst_GPS] = useState({
    lat: -23.57063,
    lng: -46.69108,
  });

  const [myMap, setMyMap] = useState(false);
  const [myOverlay, setMyOverlay] = useState();

  const [menuVisible, setMenuVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noiseRadius, setNoiseRadius] = useState(100);
  const [bounds, setBounds] = useState();
  const [drag, setDrag] = useState(true);
  const [circleRadius, setCircleRadius] = useState(2);
  const [centerRadius, setCenterRadius] = useState(5);

  var design_vector = 20;
  var hit_step = 5;
  var buildingsData = [];

  const geocodeDensity = (position, request, setauto, custom) => {
    var density;
    var geocoder = new window.google.maps.Geocoder();

    geocoder
      .geocode(request)
      .then((result) => {
        const { results } = result;
        var estado,
          municipio,
          bairro = false;
        results[0].address_components.forEach((component) => {
          if (component.types.indexOf("administrative_area_level_1") !== -1) {
            estado = component.long_name;
          }
          if (component.types.indexOf("administrative_area_level_2") !== -1) {
            municipio = component.long_name;
          }
          if (component.types.indexOf("sublocality_level_1") !== -1) {
            bairro = component.long_name;
          }
        });
        density = getDensidade(estado, municipio, bairro);
        setDensidade(density);
        window.densidade = density;
        if (custom) {
          createNoise(
            position,
            myMap,
            noiseRadius,
            buildingsData,
            window.meters,
            month,
            setauto,
            density,
            custom
          );
        } else {
          createNoise(
            position,
            myMap,
            noiseRadius,
            buildingsData,
            meters,
            month,
            setauto,
            density,
            custom
          );
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error(
          "Geocode was not successful for the following reason: " + error
        );
      });
  };

  const requestBuilding = (
    s,
    w,
    n,
    e,
    position,
    setauto = true,
    custom = false
  ) => {
    if (!setauto) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    var params = `${s},${w},${n},${e}`;
    var url = `https://ruidoneutro.com.br/nn/api/`;

    var data = new FormData();
    data.append("string", params);

    axios.defaults.withCredentials = true;

    axios({
      method: "post",
      url: url,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    }).then((res) => {
      buildingsData = [];
      setBuildings([]);
      console.log("request");
      res.data.data.forEach((obj) => {
        obj["building_area"] = getBuildingArea(obj.geometry[0]);
        obj["impact_point"] = false;

        if (
          obj.tags[0]["building:levels"] == undefined ||
          obj.tags[0]["building:levels"] == false
        ) {
          if (obj.tags[0].height) {
            obj.tags[0]["building:levels"] = Math.round(obj.tags[0].height / 3);
          } else {
            obj.tags[0]["building:levels"] = 0;
          }
        }
        buildingsData.push(obj);
      });
      setBuildings(buildingsData);
      geocodeDensity(position, { location: position }, setauto, custom);
    });
  };

  useEffect(() => {
    changeSiteLanguage();
    map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 17,
      center: const_gps,
      mapTypeId: "satellite",
      disableDefaultUI: true,
      draggable: drag,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      gestureHandling: "cooperative",
    });

    setMyMap(map);
    // console.log('init');
  }, []);

  const getPolygonCenterAndNoise = (buildingPolygons) => {
    var bounds = new window.google.maps.LatLngBounds();
    for (var p = 0; p < buildingPolygons.length; p++) {
      var coord = new window.google.maps.LatLng({
        lat: buildingPolygons[p][0],
        lng: buildingPolygons[p][1],
      });
      bounds.extend(coord);
    }

    var south = myMap.getBounds().toJSON().south;
    var west = myMap.getBounds().toJSON().west;
    var north = myMap.getBounds().toJSON().north;
    var east = myMap.getBounds().toJSON().east;
    setCurrentBuild(bounds.getCenter().toJSON());
    setMeters(Math.round(getBuildingArea(buildingPolygons)));
    setMetersLabel(Math.round(getBuildingArea(buildingPolygons)));
    window.meters = Math.round(getBuildingArea(buildingPolygons));

    if (buildingPolygons.length > 0) {
      requestBuilding(
        south,
        west,
        north,
        east,
        bounds.getCenter().toJSON(),
        true,
        true
      );
    }
  };

  const [polygon, setPolygon] = useState();

  useEffect(() => {
    window.buildingPolygons = "";
    window.buildingPolygons = buildingPolygons;
  }, [buildingPolygons]);

  const endEditing = (mapa) => {
    var paths = [];
    if (!polygon) {
      for (let p = 0; p < buildingPolygons.length; p++) {
        const point = {
          lat: buildingPolygons[p][0],
          lng: buildingPolygons[p][1],
        };
        paths.push(point);
      }

      var mypolygon = new window.google.maps.Polygon({
        paths: paths,
        editable: true,
        strokeColor: colors.primary_green,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: colors.primary_green,
        fillOpacity: 0.35,
        zIndex: "-5",
        mapa,
      });

      mypolygon.setMap(mapa);
      setPolygon(mypolygon);
      getPolygonCenterAndNoise(buildingPolygons);

      myMap.addListener("click", (e) => {
        mypolygon.setMap(null);
      });
    } else {
      try {
        setBuildingPolygons([]);
        polygon
          .getPaths()
          .getArray()[0]
          .g.forEach((coord) => {
            var polygonArray = [coord.lat(), coord.lng()];
            setBuildingPolygons((buildingPolygons) => {
              return [...buildingPolygons, polygonArray];
            });
            paths.push(polygonArray);
          });
        getPolygonCenterAndNoise(paths);
      } catch (error) {}
    }
    setIsControlVisible(true);
    setButtonVisible(true);
  };

  const createMyOverlay = () => {
    clearNoiseSVG();
    myMap.setOptions({ draggableCursor: "pointer" });

    setBuildingPolygons([]);
    myMap.addListener("click", (e) => {
      var coord = e.latLng.toJSON();
      addPointToStage(coord);
      var polygonArray = [coord.lat, coord.lng];
      setBuildingPolygons((buildingPolygons) => {
        return [...buildingPolygons, polygonArray];
      });

      try {
        polygon.setMap(null);
      } catch (error) {}
    });
  };

  const clearNoiseSVG = () => {
    try {
      document.getElementById("stage_svg").innerHTML = null;
      document.getElementById("stage_g").innerHTML = "";
      document
        .getElementById("divContainer")
        .removeChild(document.getElementById("divContainer"));
    } catch (error) {}
  };

  const addPointToStage = (point) => {
    clearNoiseSVG();
    var buildingPoint = new window.google.maps.Circle({
      name: "building-point",
      center: { lat: point.lat, lng: point.lng },
      radius: 5,
      strokeColor: colors.primary_green,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: colors.primary_green,
      fillOpacity: 0.35,
    });

    buildingPoint.setMap(myMap);
    try {
      document
        .querySelector(".conclusion-btn")
        .addEventListener("click", () => {
          buildingPoint.setMap(null);
        });

      myMap.addListener("click", () => {
        if (!editable) {
          buildingPoint.setMap(null);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (editable) {
      window.google.maps.event.clearListeners(myMap, "click");
      createMyOverlay(const_gps, myMap);
    } else {
      if (myMap) {
        geocoder = new window.google.maps.Geocoder();
        myMap.addListener("click", (event) => {
          setCurrentBuild(event.latLng);
          setBounds(myMap.getBounds());
          var south = myMap.getBounds().toJSON().south;
          var west = myMap.getBounds().toJSON().west;
          var north = myMap.getBounds().toJSON().north;
          var east = myMap.getBounds().toJSON().east;
          requestBuilding(south, west, north, east, event.latLng);
          setIsControlVisible(true);
          setButtonVisible(true);
        });
      }
    }
  }, [editable, myMap]);

  useEffect(() => {
    if (myMap) {
      myMap.addListener("dragend", (m) => {
        setBounds(myMap.getBounds());
      });
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [myMap]);

  const createNoise = (
    center,
    map,
    noiseradius,
    dataset,
    areaobra,
    meses,
    setauto,
    densidade,
    custom
  ) => {
    var waveColors = [
      "rgb(99,11,124)",
      "rgb(234,0,255)",
      "rgb(255,0,0)",
      "rgb(255,195,0)",
    ];
    var db = 55;
    var waves = 3;
    var hitted = [];
    hitted[-1] = [];
    var svg_vectors = [];
    var bigNoiseLatLngArray = [];

    for (var wave = 0; wave <= waves; wave++) {
      svg_vectors[wave] = [];
      hitted[wave] = [];
    }

    class USGSOverlay extends window.google.maps.OverlayView {
      position_;
      dataset_;
      div_;
      constructor(position, dataset) {
        super();
        this.position_ = position;
        this.div_ = null;
        this.bounds_ = null;
        this.noiseradius = noiseradius;
        this.dataset_ = dataset;
        this.list = [];
        this.clickedBuilding = null;
        this.areaNoBuilding = null;
      }

      onAdd() {
        console.log("ADD");
        this.bounds_ = map.getBounds();

        this.panes_ = this.getPanes();
        var pixelGPS = this.getProjection().fromLatLngToContainerPixel(
          this.position_
        );

        this.div_ = document.createElement("div");
        this.div_.setAttribute("id", "divContainer");
        this.div_.style.borderStyle = "none";
        this.div_.style.borderWidth = "0px";
        this.div_.style.zIndex = "10000000";
        this.div_.style.position = "absolute";

        this.svgStage_ = document.createElementNS(svgns, "svg");
        this.svgStage_.setAttributeNS(null, "id", "stage_svg");
        this.svgStage_.setAttributeNS(null, "height", "100%");
        this.svgStage_.setAttributeNS(null, "width", "100%");

        this.g_ = document.createElementNS(svgns, "g");
        this.g_.setAttributeNS(null, "name", "stage_g");
        this.g_.setAttributeNS(null, "id", "stage_g");
        this.g_.setAttributeNS(null, "fill", "none");
        this.g_.setAttributeNS(null, "stroke", "yellow");
        this.svgStage_.appendChild(this.g_);

        const distanceVector = (v1, v2) => {
          var dx = v1.x - v2.x;
          var dy = v1.y - v2.y;
          var dz = v1.z - v2.z;
          var dist = Math.sqrt(dx * dx + dy * dy + dz * dz);
          return dist;
        };

        const get_distance = (x1, y1, x2, y2) => {
          let y = x2 - x1;
          let x = y2 - y1;
          return Math.sqrt(x * x + y * y);
        };

        this.dbph_total = 0;
        const hit_test = (x, y, wave) => {
          var buildings = document.getElementsByName("building");

          if (buildings.length == 0) {
            for (let h = 0; h < hitted.length; h++) {
              if (hitted[h].length == 0) {
                let point = this.svgStage_.createSVGPoint();
                point.x = x;
                point.y = y;

                var v1 = pixelGPS;
                v1.z = 0;
                var v2 = { x: point.x, y: point.y, z: 1 };
                var dist = distanceVector(v1, v2);
                var logaritmando =
                  dist / getCenterToFrontDist(this.clickedBuilding, setauto);
                var spl = db - 20 * Math.log10(logaritmando);

                var dbph = spl * (densidade * Math.sqrt(this.areaNoBuilding));

                dbph = dbph * (meses * 216);
                this.dbph_total = dbph;
                return false;
              }
            }
            return false;
          } else {
            for (let i = 0; i < buildings.length; i++) {
              var hitted_build = false;

              if (hitted[wave - 1].includes(buildings[i])) {
                hitted_build = true;
              }

              if (hitted_build == false) {
                let point = this.svgStage_.createSVGPoint();
                point.x = x;
                point.y = y;
                if (buildings[i].isPointInFill(point)) {
                  for (
                    let l = 0;
                    l <= buildings[i].getAttribute("levels");
                    l++
                  ) {
                    var v1 = pixelGPS;
                    v1.z = 0;

                    var v2 = { x: point.x, y: point.y, z: l * 3 };
                    var dist = distanceVector(v1, v2);
                    var logaritmando =
                      dist /
                      getCenterToFrontDist(this.clickedBuilding, setauto);
                    var apePorAndar =
                      Number(buildings[i].getAttribute("building_area")) / 66;

                    var spl = db - 20 * Math.log10(logaritmando);

                    var dbph = spl * (2.9 * (apePorAndar / 2));
                    dbph = dbph * (meses * 216);
                    this.dbph_total = this.dbph_total + dbph;
                  }
                  hitted[wave].push(buildings[i]);
                  return true;
                }
              }
            }
          }
        };

        const hit_center_test = (x, y) => {
          var buildings = document.getElementsByName("building");
          for (let i = 0; i < buildings.length; i++) {
            let point = this.svgStage_.createSVGPoint();
            point.x = x;
            point.y = y;
            if (buildings[i].isPointInFill(point)) {
              this.clickedBuilding = buildings[i].getAttribute("building_area");
              // window.buildingPolygon = dataset[i].geometry[0];
              if (setauto) {
                this.clickedBuilding =
                  buildings[i].getAttribute("building_area");
              } else {
                this.clickedBuilding = areaobra;
              }
              return buildings[i].id;
            } else {
              this.clickedBuilding = areaobra;
            }
          }
        };

        const getCenterToFrontDist = (buildingarea, set_auto = true) => {
          clear_noise("center_dist");
          var path = document.createElementNS(svgns, "path");
          var dist;
          var line_SVG;
          if (set_auto) {
            dist = Math.sqrt(buildingarea) / 2;
          } else {
            dist = Math.sqrt(areaobra) / 2;
          }

          document.getElementsByName("meters")[0].value = buildingarea;
          document.querySelector(".meters-label").innerHTML =
            Math.round(buildingarea);

          line_SVG =
            "M" +
            pixelGPS.x +
            " " +
            pixelGPS.y +
            " L " +
            (pixelGPS.x + dist) +
            " " +
            pixelGPS.y;
          path.setAttributeNS(null, "d", line_SVG);
          path.setAttributeNS(null, "name", "center_dist");
          path.setAttributeNS(null, "stroke-width", 0);
          return dist;
        };

        const delay_design = (i, gps, buildings = true) => {
          for (var wave = 0; wave <= waves; wave++) {
            for (var radius = 1; radius <= wave; radius++) {
              this.noiseradius = this.noiseradius * 2;
            }

            var start;
            if (wave == 0) {
              start = [gps.x, gps.y];
              if (!buildings) {
                this.noiseradius = getCenterToFrontDist(areaobra, false) * 2;
              } else {
                this.noiseradius =
                  getCenterToFrontDist(this.clickedBuilding, setauto) * 2;
              }
            } else {
              start = svg_vectors[wave - 1][i];
              var ref_dist = get_distance(gps.x, gps.y, start[0], start[1]);
              this.noiseradius = ref_dist * 2;
            }

            var x =
              gps.x +
              this.noiseradius * Math.cos((2 * Math.PI * i) / design_vector);
            var y =
              gps.y +
              this.noiseradius * Math.sin((2 * Math.PI * i) / design_vector);

            var vector;
            var vector_d = [x, y];
            var direction = get_direction(vector_d, gps);

            for (var ii = 1; ii <= hit_step; ii++) {
              vector = get_step(hit_step, vector_d, start, ii, direction);
              if (hit_test(vector[0], vector[1], wave)) {
                ii = hit_step;
              } else {
              }
            }
            vector[2] = direction;
            svg_vectors[wave].push(vector);
            setTimeout(() => {
              add_noise(svg_vectors, true);
            }, 100);
          }
        };

        var noise_design = function (i, buildings) {
          delay_design(i, pixelGPS, buildings);
          if (i < design_vector) {
            setTimeout(() => {
              i = i + 1;
              noise_design(i, buildings);
            }, 10);
          } else {
            add_noise(svg_vectors, true);
            add_noise_outline(svg_vectors, true);
          }
        };

        this.add_circle = (x, y, color = "black", building) => {
          var svgns = "http://www.w3.org/2000/svg";
          var circle = document.createElementNS(svgns, "circle");
          circle.setAttributeNS(null, "name", "hit_test_circle");

          circle.setAttributeNS(null, "cx", x);
          circle.setAttributeNS(null, "cy", y);
          circle.setAttributeNS(null, "r", circleRadius);
          circle.setAttributeNS(
            null,
            "style",
            "fill: " + color + "; stroke: none; z-index:100;"
          );

          try {
            this.svgStage_.appendChild(circle);
          } catch (error) {
            console.error(error);
          }

          if (color == "red" && building !== false) {
            var index = building.id.split("-")[1];
            var impact_point = circle;
            this.dataset_[index]["impact_point"] = impact_point;
          }
        };

        this.add_circle_const = (x, y, radius, color = "black") => {
          try {
            document
              .getElementsByName("circle_const")[0]
              .parentNode.removeChild(
                document.getElementsByName("circle_const")[0]
              );
          } catch (error) {}

          var svgns = "http://www.w3.org/2000/svg";
          var circle = document.createElementNS(svgns, "circle");
          circle.setAttributeNS(null, "name", "circle_const");

          circle.setAttributeNS(null, "cx", x);
          circle.setAttributeNS(null, "cy", y);
          circle.setAttributeNS(null, "r", radius);
          circle.setAttributeNS(
            null,
            "style",
            "fill: transparent; stroke: none;"
          );
          document.getElementById("stage_svg").appendChild(circle);
        };

        const clear_noise = (noise_name) => {
          var noises = document.getElementsByName(noise_name);
          for (var i = 0; i < noises.length; i++) {
            noises[i].parentElement.removeChild(noises[i]);
          }
        };

        const add_noise = (svg_vectors, fill) => {
          for (var wave_d = 0; wave_d <= waves; wave_d++) {
            var wave_vectors = svg_vectors[wave_d];
            clear_noise("noise" + wave_d);
            var svgns = "http://www.w3.org/2000/svg";
            var path = document.createElementNS(svgns, "path");
            this.path_svg = "M" + pixelGPS.x + " " + pixelGPS.y;

            if (wave_d == 0) {
              for (let i = 0; i < wave_vectors.length; i++) {
                this.path_svg =
                  this.path_svg +
                  " L" +
                  wave_vectors[i][0] +
                  " " +
                  wave_vectors[i][1];
              }
            } else {
              var wave_vectors_inside = svg_vectors[wave_d - 1];
              if (wave_vectors_inside.length > 0) {
                this.path_svg =
                  "M" +
                  wave_vectors_inside[0][0] +
                  " " +
                  wave_vectors_inside[0][1];
                for (let i = 0; i < wave_vectors_inside.length; i++) {
                  this.path_svg =
                    this.path_svg +
                    " L" +
                    wave_vectors_inside[i][0] +
                    " " +
                    wave_vectors_inside[i][1];
                }
              }
              for (let i = wave_vectors.length - 1; i >= 0; i--) {
                this.path_svg =
                  this.path_svg +
                  " L" +
                  wave_vectors[i][0] +
                  " " +
                  wave_vectors[i][1];
              }
            }

            this.path_svg = this.path_svg + "Z";
            var newpath = document.createElementNS(svgns, "path");
            newpath.setAttributeNS(null, "name", "noise" + wave_d);
            newpath.setAttributeNS(null, "d", this.path_svg);
            newpath.setAttributeNS(null, "stroke", "black");
            newpath.setAttributeNS(null, "stroke-width", 0);
            newpath.setAttributeNS(null, "opacity", 0.5);
            if (fill) {
              var color = waveColors[wave_d];
              newpath.setAttributeNS(null, "fill", color);
            } else {
              newpath.setAttributeNS(null, "fill", "none");
            }

            document.getElementById("stage_svg").appendChild(newpath);
          }

          svg_vectors[svg_vectors.length - 1].forEach((point) => {
            try {
              var p = new window.google.maps.Point(point[0], point[1]);
              var newPoint = this.getProjection().fromContainerPixelToLatLng(p);
              bigNoiseLatLngArray.push([newPoint.lat(), newPoint.lng()]);
            } catch (error) {}
          });

          this.areaNoBuilding = getBuildingArea(bigNoiseLatLngArray);
        };

        const get_intra_step = (start, vector, direction, step, intra_vol) => {
          var vector_t = [];

          if (direction == 1) {
            vector_t[0] =
              start[0] + ((vector[0] - start[0]) / intra_vol) * step;
            vector_t[1] =
              start[1] - ((start[1] - vector[1]) / intra_vol) * step;
          }
          if (direction == 2) {
            vector_t[0] =
              start[0] + ((vector[0] - start[0]) / intra_vol) * step;
            vector_t[1] =
              start[1] + ((vector[1] - start[1]) / intra_vol) * step;
          }
          if (direction == 3) {
            vector_t[0] =
              start[0] - ((start[0] - vector[0]) / intra_vol) * step;
            vector_t[1] =
              start[1] + ((vector[1] - start[1]) / intra_vol) * step;
          }
          if (direction == 4) {
            vector_t[0] =
              start[0] - ((start[0] - vector[0]) / intra_vol) * step;
            vector_t[1] =
              start[1] - ((start[1] - vector[1]) / intra_vol) * step;
          }
          return vector_t;
        };

        const add_noise_outline = (svg_vectors, fill) => {
          try {
            document.querySelector(".card-group").innerHTML = "";
          } catch (error) {
            console.log(error);
          }

          var reset = [0, 0];

          var svgns = "http://www.w3.org/2000/svg";
          var circle = document.createElementNS(svgns, "circle");
          circle.setAttributeNS(null, "name", "card_circle");
          circle.setAttributeNS(null, "class", "card_center_circle");

          circle.setAttributeNS(null, "cx", reset[0]);
          circle.setAttributeNS(null, "cy", reset[1]);
          circle.setAttributeNS(null, "r", 2);
          circle.setAttributeNS(
            null,
            "style",
            "fill: " + "red" + "; stroke: none;"
          );
          document.querySelector(".card-group").appendChild(circle);

          var transformString = 0;

          var top = reset[1];
          var left = reset[0];
          var reseted_vectors = [];
          var intra_vol = 2;

          for (var wave_d = 0; wave_d <= waves; wave_d++) {
            var wave_vectors = svg_vectors[wave_d];
            var vector_reset = [];
            vector_reset[0] = wave_vectors[0][0] - pixelGPS.x + reset[0];
            vector_reset[1] = wave_vectors[0][1] - pixelGPS.y + reset[1];
            var svgns = "http://www.w3.org/2000/svg";
            var path = document.createElementNS(svgns, "path");
            this.outline_svg = "M" + vector_reset[0] + " " + vector_reset[1];

            reseted_vectors[wave_d] = [];
            reseted_vectors[wave_d][0] = vector_reset;

            for (let i = 1; i < wave_vectors.length; i++) {
              var vector_reset = [];
              vector_reset[0] = wave_vectors[i][0] - pixelGPS.x + reset[0];
              vector_reset[1] = wave_vectors[i][1] - pixelGPS.y + reset[1];

              this.outline_svg =
                this.outline_svg +
                " L" +
                vector_reset[0] +
                " " +
                vector_reset[1];
              reseted_vectors[wave_d].push(vector_reset);

              if (vector_reset[1] < top) {
                top = vector_reset[1];
              }

              if (vector_reset[0] < left) {
                left = vector_reset[0];
              }
            }

            this.outline_svg = this.outline_svg + "Z";

            var direction;
            if (wave_d > 0) {
              intra_vol = intra_vol + 1;
              for (let intra = 1; intra <= intra_vol; intra++) {
                var intra_vector = [];
                direction = svg_vectors[wave_d][0][2];
                intra_vector = get_intra_step(
                  reseted_vectors[wave_d][0],
                  reseted_vectors[wave_d - 1][0],
                  direction,
                  intra,
                  intra_vol
                );
                this.outline_svg =
                  this.outline_svg +
                  " M " +
                  intra_vector[0] +
                  " " +
                  intra_vector[1];

                for (let i = 1; i < svg_vectors[wave_d].length; i++) {
                  direction = svg_vectors[wave_d][i][2];
                  intra_vector = get_intra_step(
                    reseted_vectors[wave_d][i],
                    reseted_vectors[wave_d - 1][i],
                    direction,
                    intra,
                    intra_vol
                  );

                  this.outline_svg =
                    this.outline_svg +
                    " L " +
                    intra_vector[0] +
                    " " +
                    intra_vector[1];
                }
                this.outline_svg = this.outline_svg + "Z";
              }
            }

            var cardPath = document.createElementNS(svgns, "path");
            cardPath.setAttributeNS(null, "name", "outline-noise");
            cardPath.setAttributeNS(null, "class", "outline-mancha");
            cardPath.setAttributeNS(null, "d", this.outline_svg);
            cardPath.setAttributeNS(null, "stroke-linecap", "round");
            cardPath.setAttributeNS(null, "stroke-linejoin", "round");
            cardPath.setAttributeNS(null, "opacity", 0.5);

            if (fill) {
              var color = waveColors[wave_d];
              cardPath.setAttributeNS(null, "stroke", color);
              cardPath.setAttributeNS(null, "fill", "transparent");
            } else {
              cardPath.setAttributeNS(null, "stroke", "none");
            }

            document.querySelector(".card-group").appendChild(cardPath);
          }

          var square = [];
          var stroke;
          if (window.innerWidth < 700) {
            square = [50, 50];
            stroke = 1;
          } else {
            square = [250, 250];
            stroke = 2;
          }

          document.querySelector(".card-group").style.transform = "";
          document.querySelector(".card-group").style.strokeWidth = "";
          document.querySelector(".card_center_circle").style.strokeWidth = "";

          setTimeout(
            () => {
              var noiseArray = document.getElementsByName("outline-noise");

              var scaleH = 1;
              var scaleW = 1;
              var scale;

              scaleH =
                square[1] /
                noiseArray[noiseArray.length - 1].getBoundingClientRect()
                  .height;
              scaleW =
                square[0] /
                noiseArray[noiseArray.length - 1].getBoundingClientRect().width;

              if (scaleH < scaleW) {
                scale = scaleH;
              } else {
                scale = scaleW;
              }

              top = top * -1;
              left = left * -1;
              var topScale = top * scale;
              top = topScale;
              left = left * scale;

              top =
                top +
                (square[1] -
                  noiseArray[noiseArray.length - 1].getBoundingClientRect()
                    .height *
                    scale) /
                  2;

              left =
                left +
                (square[0] -
                  noiseArray[noiseArray.length - 1].getBoundingClientRect()
                    .width *
                    scale) /
                  2;

              transformString = `translate(${left}px, ${top}px)`;
              document.querySelector(
                ".card-group"
              ).style.transform = `${transformString} scale(${scale})`;

              var responsiveStroke = stroke / scale;
              if (responsiveStroke < 1) {
                responsiveStroke = 0.8;
              }

              document.querySelector(
                ".card-group"
              ).style.strokeWidth = `${responsiveStroke}`;
              document.querySelector(
                ".card_center_circle"
              ).style.strokeWidth = `${responsiveStroke}`;
            },
            window.innerWidth < 700 ? 1500 : 700
          );
        };

        this.get_svg = (buildingssvg) => {
          var buildings;
          if (buildingssvg == true) {
            try {
              document
                .getElementsByName("circle-center")[0]
                .parentNode.removeChild(
                  document.getElementsByName("circle-center")[0]
                );
            } catch (error) {}

            if (document.getElementsByName("stage_g").length > 1) {
              document.getElementsByName("stage_g").forEach((item) => {
                item.parentNode.removeChild(item);
              });
            }

            var circleCenter = document.createElementNS(svgns, "circle");
            circleCenter.setAttributeNS(null, "name", "circle-center");
            circleCenter.setAttributeNS(null, "cx", pixelGPS.x);
            circleCenter.setAttributeNS(null, "cy", pixelGPS.y);
            circleCenter.setAttributeNS(null, "stroke-width", "0");
            circleCenter.setAttributeNS(null, "fill", colors.primary_green);
            circleCenter.setAttributeNS(null, "fill-opacity", "1");
            circleCenter.setAttributeNS(null, "r", centerRadius);
            this.g_.appendChild(circleCenter);

            if (this.dataset_.length == 0) {
              buildings = false;
            } else {
              buildings = true;
              for (let i = 0; i < this.dataset_.length; i++) {
                var latLngtoPixel =
                  this.getProjection().fromLatLngToContainerPixel({
                    lat: this.dataset_[i].geometry[0][0][0],
                    lng: this.dataset_[i].geometry[0][0][1],
                  });

                var pathBuilding = document.createElementNS(svgns, "path");
                pathBuilding.setAttributeNS(null, "name", "building");
                pathBuilding.setAttributeNS(
                  null,
                  "levels",
                  this.dataset_[i].tags[0]["building:levels"]
                );
                pathBuilding.setAttributeNS(
                  null,
                  "building_area",
                  this.dataset_[i]["building_area"]
                );
                pathBuilding.setAttributeNS(null, "id", `build-${i}`);
                pathBuilding.setAttributeNS(null, "stroke-width", "0");
                pathBuilding.setAttributeNS(null, "stroke", "none");
                pathBuilding.setAttributeNS(null, "fill", "transparent");
                pathBuilding.setAttributeNS(null, "fill-opacity", "1");

                var mCoords = `M${latLngtoPixel.x} ${latLngtoPixel.y}`;
                var lCoords = "";

                for (
                  var ii = 1;
                  ii < this.dataset_[i].geometry[0].length;
                  ii++
                ) {
                  var xyBuilding =
                    this.getProjection().fromLatLngToContainerPixel({
                      lat: this.dataset_[i].geometry[0][ii][0],
                      lng: this.dataset_[i].geometry[0][ii][1],
                    });
                  lCoords = `${lCoords} L${xyBuilding.x}  ${xyBuilding.y}`;
                }
                pathBuilding.setAttributeNS(null, "d", `${mCoords} ${lCoords}`);
                this.g_.appendChild(pathBuilding);
              }
            }
          }

          try {
            this.customBuilding();
          } catch (error) {}
          this.svgStage_.appendChild(this.g_);
          this.div_.appendChild(this.svgStage_);

          setTimeout(() => {
            try {
              var build = document.getElementById(
                hit_center_test(pixelGPS.x, pixelGPS.y)
              );
              build.parentNode.removeChild(build);
            } catch (error) {}

            setTimeout(() => {
              this.add_circle_const(
                pixelGPS.x,
                pixelGPS.y,
                getCenterToFrontDist(this.clickedBuilding, setauto)
              );
              noise_design(0, buildings);
            }, 50);
          }, 500);
        };

        const hit_custom_building = (polygon) => {
          // console.log(polygon);
          dataset.forEach((building, i) => {
            var polygon = building.geometry[0];
            var bounds = new window.google.maps.LatLngBounds();

            for (var p = 0; p < polygon.length; p++) {
              var coord = new window.google.maps.LatLng({
                lat: polygon[p][0],
                lng: polygon[p][1],
              });
              bounds.extend(coord);
            }

            var otherBuildingsCenter = bounds.getCenter().toJSON();
            otherBuildingsCenter =
              this.getProjection().fromLatLngToContainerPixel(
                otherBuildingsCenter
              );

            let point = this.svgStage_.createSVGPoint();
            point.x = otherBuildingsCenter.x;
            point.y = otherBuildingsCenter.y;

            var customBuildingEl = document.getElementById("custom-building");

            if (customBuildingEl.isPointInFill(point)) {
              document
                .getElementById(`build-${i}`)
                .parentNode.removeChild(document.getElementById(`build-${i}`));
            }
          });
        };

        this.customBuilding = () => {
          var customBuilding = document.createElementNS(svgns, "path");
          customBuilding.setAttributeNS(null, "name", "custom-building");
          customBuilding.setAttributeNS(null, "stroke-width", "0");
          customBuilding.setAttributeNS(null, "fill", "transparent");
          customBuilding.setAttributeNS(null, "opacity", ".5");
          customBuilding.setAttributeNS(null, "id", `custom-building`);
          var latLngtoPixel = this.getProjection().fromLatLngToContainerPixel({
            lat: window.buildingPolygons[0][0],
            lng: window.buildingPolygons[0][1],
          });

          var mCoords = `M${latLngtoPixel.x} ${latLngtoPixel.y}`;
          var lCoords = "";

          for (let i = 0; i < window.buildingPolygons.length; i++) {
            var xyBuilding = this.getProjection().fromLatLngToContainerPixel({
              lat: window.buildingPolygons[i][0],
              lng: window.buildingPolygons[i][1],
            });
            lCoords = `${lCoords} L${xyBuilding.x}  ${xyBuilding.y}`;
          }

          customBuilding.setAttributeNS(null, "d", `${mCoords} ${lCoords}`);
          this.g_.appendChild(customBuilding);

          setTimeout(() => {
            hit_custom_building(window.buildingPolygons);
          }, 500);
        };

        this.get_svg(true);
        const panes = this.getPanes();
        panes.overlayLayer.appendChild(this.div_);
        setBounds(map.getBounds());

        map.addListener("dragend", (m) => {
          setBounds(map.getBounds());

          try {
            this.svgStage_ = null;
            this.g_.innerHTML = "";
            this.div_.parentNode.removeChild(this.div_);
          } catch (error) {}
        });
        map.addListener("click", (m) => {
          try {
            this.svgStage_ = null;
            this.g_.innerHTML = "";
            this.div_.parentNode.removeChild(this.div_);
          } catch (error) {}
        });

        ReactGA.event({
          category: "Tool",
          action: "Created a noise impact path",
        });
      }

      draw() {
        setTimeout(() => {
          window.ni_total = "";
          window.ni_total = this.dbph_total;
          var result = this.dbph_total / window.divisor;

          if (document.querySelector(".ni-result") == undefined) {
            var el = document.createElement("span");
            el.setAttribute("class", "ni-result");
            el.innerText = `${Math.round(result)}`;

            document.querySelector(".result").classList.add("visible");
            document
              .querySelector(".ni")
              .insertBefore(el, document.querySelector(".asterisco"));
          } else {
            document.querySelector(".ni-result").innerText = Math.round(result);
          }
        }, 1500);

        const overlayProjection = this.getProjection();
        const sw = overlayProjection.fromLatLngToDivPixel(
          this.bounds_.getSouthWest()
        );
        const ne = overlayProjection.fromLatLngToDivPixel(
          this.bounds_.getNorthEast()
        );

        if (this.div_) {
          this.div_.style.left = sw.x + "px";
          this.div_.style.top = ne.y + "px";
          this.div_.style.width = ne.x - sw.x + "px";
          this.div_.style.height = sw.y - ne.y + "px";
        }
      }

      onRemove() {
        if (this.div_) {
          console.log("REMOVE");
        }
      }
    }

    const overlay = new USGSOverlay(center, dataset);
    setMyOverlay(overlay);
    overlay.setMap(map);

    map.addListener("click", (m) => {
      // overlay.setMap(null);
    });

    map.addListener("center_changed", (m) => {
      overlay.setMap(null);
    });

    setResultVisible(true);
  };

  const textToAddress = (text) => {
    geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, function (results, status) {
      if (status == "OK") {
        myMap.setCenter(results[0].geometry.location);
        setCurrentBuild(results[0].geometry.location);
        setBounds(myMap.getBounds());
        var south = myMap.getBounds().toJSON().south;
        var west = myMap.getBounds().toJSON().west;
        var north = myMap.getBounds().toJSON().north;
        var east = myMap.getBounds().toJSON().east;

        setTimeout(() => {
          requestBuilding(
            south,
            west,
            north,
            east,
            results[0].geometry.location
          );
          setButtonVisible(true);
          setIsControlVisible(true);
        }, 100);
      } else {
        alert("Geocode was not successful for the following reason: " + status);
      }
    });
  };

  const handleInput = (e) => {
    setAddress(e.target.value);
    ReactGA.event({
      category: "Tool",
      action: "Search an address",
    });
  };

  const handleMenu = (link) => {
    if (link[0] === "#") {
      navigate(`/${link}`);
    } else {
      navigate(link);
    }
    setMenuVisible(false);
  };

  useEffect(() => {
    window.monthSeletor = monthLabel;
  }, [monthLabel]);

  const handleMonthChange = (e) => {
    try {
      myOverlay.setMap(null);
      var b = bounds.toJSON();

      setTimeout(() => {
        createNoise(
          currentBuild,
          myMap,
          noiseRadius,
          buildings,
          meters,
          month,
          false,
          densidade
        );
      }, 50);
    } catch (error) {}
  };

  const handleAreaChange = (e) => {
    try {
      myOverlay.setMap(null);
      var b = bounds.toJSON();

      setTimeout(() => {
        createNoise(
          currentBuild,
          myMap,
          noiseRadius,
          buildings,
          meters,
          month,
          false,
          densidade
        );
      }, 50);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (cardsNI && window.innerWidth < 700) {
      document.querySelector(".tool-wrapper").style.flexDirection = "column";
      document.querySelector(".tool-wrapper").style.justifyContent = "center";
    }

    if (cardsNI) {
      document.querySelector(".tool-wrapper").style.alignItems = "start";
      document.querySelector(".tool-container").style.alignItems = "start";
      document.querySelector(".controls-comp").style.justifyContent = "end";
      if (window.innerWidth > 1500) {
        document.querySelector(".tool-wrapper").style.marginTop = "25vh";
      }

      if (window.innerWidth > 700 && window.innerWidth < 1500) {
        document.querySelector(".tool-wrapper").style.marginTop = "10vh";
      }
    }
  }, [cardsNI]);

  return (
    <div
      className="tool-container"
      style={{
        position: "fixed",
        width: windowW,
        height: windowH,
        overflow: "hidden",
        backgroundImage: `url("/assets/img/background.png")`,
      }}
    >
      <div className="line"></div>
      <NavBar
        handleMenu={handleMenu}
        className={menuVisible ? "visible" : ""}
        lang={lang}
        setMenuVisible={setMenuVisible}
      />

      <LoadingScreen className={loading ? "loading" : ""}>
        <MotionBars width={150} timing={"infinite"} />
        <br />
        <p className="loading-text">{cms[lang].tool.loading.paragraph}</p>
      </LoadingScreen>
      <div className="tool-wrapper">
        <Controls
          editable={editable}
          isControlVisible={isControlVisible}
          cardStage={cardStage}
          className="controls-comp"
          cardsNI={cardsNI}
          searchFocus={searchFocus}
          isConfigVisible={isConfigVisible}
          resultVisible={resultVisible}
        >
          <img
            className="mobile back-arrow"
            src="/assets/svg/back.svg"
            onClick={() => {
              navigate("/");
            }}
          />
          <div className="card">
            <div className="result">
              <p className="ni">
                <span
                  className="asterisco"
                  style={{ fontFamily: "Moderat-Mono-Regular" }}
                >
                  *
                </span>
                <span className="ni-unit">NI</span>

                {window.innerWidth < 700 ? (
                  <svg className=" card-stage mobile">
                    <g className="card-group"></g>
                  </svg>
                ) : null}
              </p>
              <img className="rastro" src="/assets/img/rastro.png" alt="" />
            </div>
            <div className="building-info">
              <p className="instruction">
                {" "}
                <span className="number">1.</span>{" "}
                {cms[lang].tool.controls.title_1}
              </p>
              <div className="building-item">
                <input
                  type="text"
                  name="address"
                  id="address"
                  onChange={handleInput}
                  placeholder="Pesquise um endereço..."
                />
                <img
                  src={"/assets/img/lupa.svg"}
                  className="ok-btn"
                  onClick={() => {
                    textToAddress(address);
                  }}
                />
              </div>

              {!editable ? (
                <NNLink
                  to={false}
                  iconbefore="false"
                  type={"green"}
                  icon={"edit"}
                  label={cms[lang].tool.controls.custom_build.inactive}
                  className="edit-btn"
                  editable={editable}
                  onClick={() => {
                    editable ? setEditable(false) : setEditable(true);
                  }}
                />
              ) : (
                <div>
                  <span className="custom-info">
                    {cms[lang].tool.controls.custom_build.instruction}
                  </span>
                  <NNLink
                    to={false}
                    editable={editable}
                    type={"green"}
                    label={cms[lang].tool.controls.custom_build.active}
                    className={"conclusion-btn"}
                    onClick={() => {
                      endEditing(myMap);
                      setEditable(false);
                    }}
                  />
                </div>
              )}
              <p className={`instruction active_second_step`}>
                <span className="number">2.</span>{" "}
                {cms[lang].tool.controls.title_2}
              </p>

              <div className="building-item active_second_step">
                <p className="active_second_step">
                  {cms[lang].tool.controls.ranges[0].title}
                </p>
                <p className="active_second_step">
                  {monthLabel} {cms[lang].tool.controls.ranges[0].unit}
                </p>
              </div>

              <input
                className="active_second_step"
                onChange={(e) => {
                  setMonthLabel(e.target.value);
                  setMonth(e.target.value);
                }}
                onTouchEnd={(e) => {
                  handleMonthChange(e);
                  setButtonVisible(true);
                }}
                onMouseUp={(e) => {
                  handleMonthChange(e);
                  setButtonVisible(true);
                }}
                type="range"
                min={12}
                defaultValue={month}
                max={60}
              />

              <div className="building-item active_second_step">
                <p className="active_second_step">
                  {cms[lang].tool.controls.ranges[1].title}
                </p>
                <p className="active_second_step">
                  <span className="meters-label">{metersLabel}</span>{" "}
                  {cms[lang].tool.controls.ranges[1].unit}
                </p>
              </div>

              <input
                name="meters"
                className="active_second_step"
                onChange={(e) => {
                  setMetersLabel(e.target.value);
                  setMeters(e.target.value);
                }}
                onTouchEnd={(e) => {
                  handleAreaChange(e);
                  setButtonVisible(true);
                }}
                onMouseUp={(e) => {
                  handleAreaChange(e);
                  setButtonVisible(true);
                }}
                type="range"
                min={100}
                defaultValue={meters}
                step={10}
                max={10000}
              />
              <br />
              {isControlVisible && (
                <NNButton
                  className="nn-btn"
                  onClick={(e) => {
                    setIsConfigVisible(false);
                    setCardsNI(true);
                    setCardStage(true);
                    ReactGA.event({
                      category: "Tool",
                      action: "Go to reduction projects",
                    });
                  }}
                  buttonVisible={buttonVisible}
                  label={cms[lang].tool.controls.cta}
                  type={"green"}
                />
              )}
            </div>

            <div className="card-noise desktop">
              <div className="card-noise-bg"></div>
              {window.innerWidth > 700 ? (
                <svg className="card-stage">
                  <g className="card-group"></g>
                </svg>
              ) : null}
            </div>
            <div className="base-number">* ni/{window.divisor}</div>
          </div>
        </Controls>

        {cardsNI ? (
          <ProjectsContainer className={"mobile"} active={cardsNI} />
        ) : (
          ""
        )}

        <div id={`map`} style={{ display: cardsNI ? `none` : `block` }}></div>

        {cardsNI ? (
          <ProjectsContainer className={"desktop"} active={cardsNI} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default NoiseNeutralTool;
