import setCookie from "./setCookie";
import axios from "axios";

const getCsrfToken = () => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/nn/csrf/`, {
        withCredentials: true,
    }).then(data => {
        setCookie('csrftoken', data.data, 1);
    })
}

export default getCsrfToken;
