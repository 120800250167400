import React from "react";
import styled, { css } from "styled-components";
import colors from "./utils/colors.json";

const Container = styled.div`
  border-radius: 20px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-family: "NBInterBold";
  letter-spacing: 0.15em;
  text-align: center;
  
  text-transform: uppercase;
  opacity: ${(props) => (props.buttonVisible ? "1" : "0")};
  transition: all 0.5s ease-in;
  cursor: pointer;

  div{
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  svg{
    height: 1.5em;
  }

  ${(props) =>
    props.type == "black-3"
      ? css`
          text-transform: lowercase;
          background-color: ${colors.secondary_black};
          color: ${colors.primary_green};
          font-size: 18px;
          width: fit-content;
          gap: 5px;
          border-radius: 15px;
          padding: 10px 20px;
          margin-top: 0px;

          p {
            height: auto;
            margin: 0 !important;
            margin-bottom: 0 !important;
          }
          img {
            height: 50%;
          }
        `
      : null}

  ${(props) =>
    props.type == "black-2"
      ? css`
          background-color: ${colors.secondary_black};
          color: ${colors.primary_green};
          font-size: 13px;
          width: fit-content;
          gap: 5px;
          border-radius: 15px;
          padding: 10px 20px;

          p {
            height: auto;
          }
          img {
            height: 50%;
          }
        `
      : null}

    ${(props) =>
    props.type == "black"
      ? css`
          background-color: ${colors.secondary_black};
          color: ${colors.secondary_white};
          font-size: 15px;
          text-transform: lowercase;
          width: fit-content;
          gap: 5px;
          border-radius: 15px;
          padding: 5px 15px;

          p {
            height: auto;
          }
          img {
            height: 50%;
          }
        `
      : null}

    ${(props) =>
    props.type == "gray"
      ? css`
        background-color: ${colors.primary_gray};
        color: ${colors.secondary_black}};
        font-size: 14px;
        width: fit-content;
        `
      : null}


    ${(props) =>
    props.type == "green"
      ? css`
        background-color: ${colors.primary_green};
        color: ${colors.secondary_black}};
        font-size: 14px;
        width: 100%;
        `
      : null}

    ${(props) =>
    props.type == "outline"
      ? css`
          background-color: transparent;
          color: ${colors.primary_white};
          font-size: 14px;
          border: 1px solid ${colors.primary_white};
          width: fit-content;
          white-space: nowrap;
        `
      : null}

    ${(props) =>
    props.iconbefore &&
    css`
      width: 100% !important;
    `}

    p {
    margin: 0;
  }

  transition: all 0.1s ease-in;
  &:active {
    transform: scale(0.95);
  }
`;

const NNButton = ({
  label,
  ref,
  type,
  iconbefore = false,
  icon,
  onClick,
  buttonVisible = true,
  ...props
}) => {
  var icons = {
    play: (
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 7L3.01142e-07 13.9282L9.06825e-07 0.0717964L12 7Z"
          fill="#D9D9D9"
        />
      </svg>
    ),
    arrow: <img src="/assets/img/arrow.svg"></img>,
    plus: (
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.56667 11V0H6.43333V11H4.56667ZM0 6.43333V4.56667H11V6.43333H0Z"
          fill="white"
        />
      </svg>
    ),
    edit: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 34.98 36.2"
      >
        <path
          d="M-743.18,868.56a3.38,3.38,0,0,0-3.27-2.53,3.37,3.37,0,0,0-3.38,3.37,3.38,3.38,0,0,0,2.53,3.27v29.56h1.7V872.67a3.39,3.39,0,0,0,2.42-2.42h28.33v-1.69Z"
          transform="translate(749.83 -866.03)"
          fill="#000"
        />
        <path
          d="M-729.92,899.83l2.72-9.52-1.74-1.74h0a1.12,1.12,0,0,1-.28-1.06,1.09,1.09,0,0,1,.77-.77,1.12,1.12,0,0,1,1.06.28l1.81,1.82,9.45-2-23-10Z"
          transform="translate(749.83 -866.03)"
          fill="#000"
        />
      </svg>
    ),
  };

  return (
    <Container
      ref={ref}
      iconbefore={iconbefore}
      className="nn-btn"
      buttonVisible={buttonVisible}
      onClick={onClick}
      type={type}
      {...props}
    >
      {!iconbefore ? (
        <div>
          <p>{label}</p> {icons[icon]}
        </div>
      ) : (
        <div>
          <p>{icons[icon]}</p> {label}
        </div>
      )}
    </Container>
  );
};

export default NNButton;
