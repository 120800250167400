import React from "react";
import styled from "styled-components";
import colors from './utils/colors.json'
import cms from "./utils/copywriting.json"
import NNButton from "./NNButton";
import { useNavigate } from "react-router-dom";
import useLanguageStore from "./useLanguageStore";
import { useState } from "react";
import MotionBars from "./MotionBars";
import { useEffect } from "react";

const Container = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    margin-top: -1px;
    padding: 100px;
    /* padding-left: 0; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.primary_black};
    background-image: url('/assets/img/background.png');
    background-size: cover;

    .arrowScroll{
        position: absolute;
        bottom: 50px;
        width: 100%;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img{
            width: 50px;
            height: auto;
        }
    }

    .problem-content{
        width: 50%;
        max-width: 600px;

        .logo-who{
            width: auto;
            height: 50px;
            margin-bottom: 30px;
        }
    }

    .problem-number{
        margin-top: 90px;
        margin-right: 100px;
    }

    .problem-video {
        margin-left: 25px;
        width: 40%;
        max-width: 800px;

        .problem-quote{
            color: ${colors.primary_gray};
            text-transform: uppercase;
            font-family: 'Anybody-Regular';
            text-align: center;
            font-size: clamp(1rem, 2.0vw, 2.3rem);
            width: 100%;
            max-width: 450px;
            hyphens: none !important;
        }
        
        iframe{
            width: 100%;
            height: auto;
            border-radius: 20px;
            aspect-ratio: 16 / 9;
        }
    }

    .problem-text-block{
        /* background-color: green; */
        display: flex;
        align-items: flex-start;
        justify-content: start;
        gap: 20px;
    }
  
    .problem-paragraph{
        font-family: 'Anybody-Regular';
        line-height: 1.5em;
        color: ${colors.primary_gray};
        font-size: clamp(0.9rem, 1.1vw, 1.2rem);
        
        p{
            margin: 0;
            margin-bottom: 20px;
        }
        .nn-btn p{
            margin: 0 !important;
        }
    }

    .problem-paragraph span{
        color: ${colors.primary_green};
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: space-between;
        min-height: auto;
        /* align-items: flex-start; */
        padding:50px 20px;
        gap: 50px;

        .problem-content, .problem-video{
            width: 100%;
            margin: 0;
        }

        .problem-paragraph{
            line-height:1.8em;
        }
        
        .problem-video{
            position: relative;
            iframe{
                /* position: absolute; */
                margin-left: -20px;
                width: calc(100% + 40px);
                border-radius: 0;
            }
        }

        .problem-quote{
            font-size: 25px !important;
        }

        .arrowScroll{
            position: relative;
            bottom: 0px;
            /* margin-top: auto; */
            
            img{
                width: 20px;
            }
        }
    }

    @media screen and (min-width: 1500px) {
        padding: 100px 20vw;
      
        .problem-content{
            max-width: 700px;
        }

        .problem-quote{
            max-width: 550px !important;
        }
    }
`

const TheProblem = ({ ReactGA, ...props }) => {

    var lang = useLanguageStore((state) => state.language);
    const navigate = useNavigate();

    const putParagraphs = (lang) => {
        var array = [];
        cms[lang].problem.paragraphs.forEach((p, key) => {
            array.push(<p className="problem-paragraph" key={key} dangerouslySetInnerHTML={{ __html: p }} />);
        })
        return array;
    }

    return (
        <Container id="problem">
            <div className="problem-content">
                <div className="problem-text-block">
                    <div className="problem-number">01</div>
                    <div className="problem-paragraph">
                        <img className="logo-who" src="/assets/logos/WHO.png" alt="" />

                        {putParagraphs(lang)}
                        {/* <NNButton label={cms[lang].problem.cta} onClick={() => {
                            navigate('/calculate');
                            ReactGA.event({
                                category: 'interaction',
                                action: 'Go to Tool'
                            });
                        }} type={'black-2'} /> */}
                    </div>
                </div>

            </div>

            <div className="problem-video">
                <p className="problem-quote">''{cms[lang].problem.quote}''</p>
                {/* <iframe width="560" height="315"
                    src="https://www.youtube.com/embed/QmHbwI38CvA?controls=0"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen>
                </iframe> */}
            </div>
            
            <div className="arrowScroll" onClick={()=>{
                const element = document.querySelector(`#galery`);
                element.scrollIntoView({ behavior: 'smooth' });
            }}> <img src="/assets/img/arrow-scroll.png" alt="" /> </div>
        </Container>
    )
}

export default TheProblem;