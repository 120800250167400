import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import cms from "./utils/copywriting.json"
import colors from "./utils/colors.json"
import useLanguageStore from "./useLanguageStore";
import { useNavigate } from "react-router-dom";
import NNButton from "./NNButton";
import { useEffect } from "react";
import axios from "axios";
import getCsrfToken from "./utils/getCsrfToken";
import getCookie from "./utils/GetCookie";

const Container = styled.div`  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 50px;
    padding-bottom: 20px;
    min-height: 100vh;
    background-image: url("/assets/img/backgrouns-gradient.png");
    background-size: cover;

    .apoio-container{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        gap: 30px;
        padding-bottom: 30px;
        box-sizing: border-box;
    }

    .apoio{
        font-family: 'Anybody-Regular';
        color: ${colors.primary_gray};
        border-bottom: 1px solid ${colors.primary_gray};
        width: 100px;
        margin: 0;
        /* margin-top: -20px;
        padding-bottom: 5px; */
    }


    .footer-logos{
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        gap: 30px;

        img{
            height: 25px;
            width: auto;
        }
    }

    .send-container{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        box-sizing: border-box;
        gap: 20px;
    }
    
    .footer-container{
        z-index: 10;
    }

    .footer{
        /* position: absolute; */
        width: 100%;
        height: auto;
        bottom:40px;
    }

    .docs{
        width: 100%;
        left: 0;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        padding-bottom: 20px;
        a{
            color: ${colors.primary_gray};
            /* text-decoration: none; */
        }
    }

    .send-background{
        position: absolute;
        width: 100vw;
        height: 90vh;
        z-index: 1;
        background-image: ${props => `url(${props.bg})`};
        background-position: center;
        background-size: 40% auto;
        background-repeat: no-repeat;    
    }

    .send-column-1, .send-column-2, .send-column-3{
        transition: all .5s ease;
    }

    ${props => props.sendVisible ?
        `
        @media screen and (max-width: 600px) {
            gap: 15px;

        .send-column-1, .send-column-2, .send-column-3{
                width: 100% !important;
                height: initial !important;
                margin: 0 !important;
                display: block;
            }
        }

        .send-background{
            display: none;
        }
    
    `:
        css`
        .send-column-2, .send-column-3{
            width: 0 !important;
            height: 0 !important;
            padding: 0 !important;
            border: none !important;
            overflow: hidden;
            display: none;
        }

       
    `}
    .send-column-1{
        z-index: 5;
        /* margin-top: 20px; */
        max-width: 400px;

        p{
            color: ${colors.primary_gray};
            font-family: 'Anybody-Regular';
            line-height: 1.5em;
            margin-left: 5px;
            font-size: clamp(.6rem, .9vw, .8rem);
            letter-spacing: 1px;
        }
    }

    .send-column-button{
        display: flex;
        font-family: 'Anybody-Medium';
        align-items: center;
        justify-content: space-between;
        background-color: ${colors.secondary_gray} ;
        color: ${colors.primary_green};
        text-transform: uppercase;
        gap: 100px;
        padding: 0 20px;
        box-sizing: border-box;
        border-radius: 10px;
        cursor: pointer;

        p{
            color: ${colors.primary_green};
            margin-left: 0px;
            font-size: 18px;
        }

        .arrow{
            font-family: 'NBLIGHTRMS';
            transform: rotateZ(90deg);
            -webkit-transform: rotate(90deg);
            font-size: 28px;

        }
    }

    .send-column-2, .send-image-container{
        z-index: 5;  
        border: 1px solid ${colors.primary_green};
        border-radius: 10px;
        box-sizing: border-box;
        padding: 10px;

        .send-title{
            background-color: ${colors.secondary_black};
            color: ${colors.secondary_white};
            font-family: 'Anybody-Medium';
            font-size: 14px;
            margin: 10px 0;
            /* margin-top: 0; */
            padding: 5px;
            width: fit-content;
            border-radius: 5px;
        }
    }

    .send-image-container{
        position: relative;
        background-image: url("/assets/img/send-bg.png");
        background-size: cover;
        background-position: center;
        height: 350px;
        min-width: 350px;

        ${props => props.dragging ?
        `border: 5px dotted ${colors.primary_green};
        `
        :
        ``}
    }

    .send-column-2{
        /* margin-top: 100px; */
        height: 50%;
        min-height: 600px;
        width: 400px;

        .send-input, textarea{
            background-color: ${colors.secondary_gray};
            border: none;
            font-family: 'Anybody-Medium';
            color: ${colors.primary_green};
            letter-spacing: 2px;
            line-height: 1.3em;
            border-radius: 10px;
            outline: none;
            box-sizing: border-box;
            padding: 10px;
        }

        textarea{
            width: 100%;
            position: relative;
            height: 300px;
            border: none;
            font-size: 14px;
            resize: none;
        }

        .send-input{
            width: 95%;
        }
    }

    .send-column-3{
        z-index: 5;  
        /* margin-top: 100px; */
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .send-file-name{
        background-color: ${colors.primary_green};
        width: fit-content;
        text-align: center;
    }

    .label-error{
        display: none;
        color: #d43939;
        font-family: 'Moderat-Mono-Regular';
        font-weight: 500;
    }

    @media screen and (max-width: 600px) {
        padding: 50px 20px;
        height: auto !important;
        min-height: auto;
        justify-content: center;
        gap: 50px;

        .send-container{
            flex-direction: column;
            height: auto !important;
        }

        .send-column-2{
            textarea{
                height: 250px;
            }
        }

        .send-background{
            display: none;
        }

        .send-image-container{
            background-image:none;
            height: auto;
        }

        .apoio-container{
            flex-direction: column;
        }

        .apoio{
            border-bottom: 1px solid ${colors.primary_gray};
            width: 100%;
            margin: 0;
        }

        .footer-logos{
            flex-wrap: wrap;

            img{
                height: 20px;
            }
        }

    }
`

const DropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 80%;
  max-height: 300px;
  position: absolute;

  @media screen and (max-width: 600px) {  
    position: relative;
    height: auto;
    
  }
  .drop-text{
    width: 100%;
  }

  ${props => props.dragging ?
        `
        background-image: red;
        `
        :
        `
        background-image: red;
    `}
`;

const DropzoneInput = styled.input`
  /* display: none; */
  background-color: ${colors.primary_gray};
  width: 60%;
  padding: 5px;
  border-radius: 10px;

  @media screen and (max-width: 600px) {  
    width: 95%;    
  }
`;

const DropzoneLabel = styled.label`
  padding: 8px 16px;
  width: 100%;
  height: 80%;
  text-align: center;
  background-color: #ddddddca;
  border-radius: 4px;
  cursor: pointer;
  visibility: hidden;
  top: 0;
  position: absolute;

  @media screen and (max-width: 600px) {  
    display: none;

  }
`;

const Dropzone = ({ setDragging, dragging, onDrop }) => {

    const handleDragEnter = e => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = e => {
        e.preventDefault();
        setDragging(false);
    };

    const handleDragOver = e => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
    };

    const handleDrop = e => {
        e.preventDefault();
        setDragging(false);
        const file = Array.from(e.dataTransfer.files);
        onDrop(file);
    };

    const handleFileSelect = e => {
        const file = Array.from(e.target.files);
        onDrop(file);
    };

    useEffect(() => {
        // console.log(dragging)
    }, [dragging])

    return (
        <DropzoneWrapper
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            dragging={dragging}
        >

            <DropzoneInput type="file" multiple onChange={handleFileSelect} />
            <DropzoneLabel htmlFor="file-input">{dragging ? 'Drop files here' : 'Clique ou arraste o arquivo'}</DropzoneLabel>
        </DropzoneWrapper>
    );
};

const SendYourProject = () => {

    const [dragging, setDragging] = useState(false);
    const [file, setFile] = useState([]);
    
    const [sendVisible, setSendVisible] = useState(false);
    const sendData = useRef({
        "username": '',
        "password": '',
        "project_name": '',
        "file": ''
    });

    var lang = useLanguageStore((state) => state.language);
    const navigate = useNavigate();

    const handleDrop = droppedFile => {
        setFile([...file, ...droppedFile]);
    };

    useEffect(() => {
        getCsrfToken();
    }, [])

    const sendResquest = (e) => {
        e.preventDefault();
        // getCsrfToken();

        axios.defaults.withCredentials = true;
        axios.defaults.xsrfCookieName = "csrftoken";
        axios.defaults.headers.common['X-CSRFToken'] = getCookie('csrftoken');
        // console.log(getCookie('csrftoken'))

        var createuserURL = `${process.env.REACT_APP_SERVER_URL}/nn/createUser/`
        var uploadProject = `${process.env.REACT_APP_SERVER_URL}/nn/uploadProject/`

        var userAuth = new FormData;
        userAuth.append('username', sendData.current.username);
        userAuth.append('password', sendData.current.password);

        axios({
            method: 'post',
            url: createuserURL,
            // headers: {
            //     'Content-Type': 'multipart/form-data',
            // },
            withCredentials: true,
            data: userAuth
        })
            .then(res => {
                if (res.data.status === "user created" || res.data.status === "user already exists") {
                    // getCsrfToken();

                    var projectData = new FormData;
                    projectData.append('username', sendData.current.username);
                    projectData.append('project_name', sendData.current.project_name);
                    projectData.append('description', sendData.current.description);
                    projectData.append('file', file[0]);

                    axios({
                        method: 'post',
                        url: uploadProject,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        data: projectData
                    })
                        .then(res => {
                            // console.log(res);
                        })
                } else {
                    // console.log(res.data.status)
                }
            })
    }

    const handleInput = (e) => {
        sendData.current[e.target.name] = e.target.value;
    }

    return (
        <Container id="submit" dragging={dragging} sendVisible={sendVisible} bg={cms[lang].send.bg}>
            <div className="send-container">
                <div className="send-background">
                </div>

                <div className="send-column-1" onClick={() => { setSendVisible(true); }}>
                    <div className="send-column-button">
                        <p>{cms[lang].send.title}</p>
                        <div className="arrow">&#62;</div>
                    </div>
                    <p>{cms[lang].send.subtitle}</p>
                </div>

                <div className="send-column-2">
                    <p className="send-title">{cms[lang].send.username}</p>
                    <input className="send-input" name="username" placeholder={cms[lang].send.placeholders.username} onChange={handleInput} type="e-mail" />

                    <p className="send-title">{cms[lang].send.password}</p>
                    <input className="send-input" name="password" placeholder={cms[lang].send.placeholders.password} onChange={handleInput} type="password" />

                    <p className="send-title">{cms[lang].send.project_name}</p>
                    <input className="send-input" name="project_name" onChange={handleInput} placeholder={cms[lang].send.placeholders.project_name} type="text" />

                    <p className="send-title">{cms[lang].send.description} </p>
                    <textarea placeholder={cms[lang].send.placeholders.description} name="description" onChange={handleInput} />
                </div>

                <div className="send-column-3">
                    <div className="send-image-container">
                        <p className="send-title">{cms[lang].send.image}</p>

                        <Dropzone dragging={dragging} setDragging={setDragging} onDrop={handleDrop} />
                        {file.map((file, index) => (
                            <p className="send-file-name" key={index}>{file.name}</p>
                        ))}
                    </div>
                    <NNButton onClick={sendResquest} label={cms[lang].send.cta} type={'green'} />

                    <p className="label-error">* Senha incorreta</p>
                </div>


            </div>

            <div className="footer-container">
                {/* <img className="desktop footer" src="/assets/img/footer.png" alt="" />
            
                <img className="mobile footer" src="/assets/img/footer-mobile.png" alt="" /> */}
                <div className="apoio-container">
                    <p className="apoio">{cms[lang].footer.title}</p>
                    <div className="footer-logos">
                        <img src="/assets/img/tegra-logo.png" alt="" />
                        <img src="/assets/img/zoom-logo.png" alt="" />
                        <img src="/assets/img/bracustica-logo.png" alt="" />
                        <img src="/assets/img/santa-barbara-logo.png" alt="" />
                        <img src="/assets/img/aviva-logo.png" alt="" />
                        <img src="/assets/img/hantei-logo.png" alt="" />
                        <img src="/assets/img/lupa-logo.png" alt="" />
                        <img src="/assets/img/zaltsman-logo.png" alt="" />
                        <img src="/assets/img/flic-logo.png" alt="" />
                    </div>
                </div>

                <div className="docs">
                    <a href="/termos_de_uso.pdf" target="_blank">{cms[lang].footer.title_terms}</a>
                    <a target="__blank" href="/politica_cookies.pdf">{cms[lang].footer.title_cookies}</a>
                </div>
            </div>
        </Container>
    )
}

export default SendYourProject;