
import React from "react";
import styled from "styled-components";
import colors from './utils/colors.json'
import cms from "./utils/copywriting.json"
import useLanguageStore from "./useLanguageStore";
import { useNavigate } from "react-router-dom";

const NavContainer = styled.div`
    position: fixed;
    box-sizing: border-box;
    padding: 25px;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: auto;
    font-family: 'Anybody-Medium';
    letter-spacing: 1.5px;
    z-index: 1;

    .square{
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    .menu-bar{
        display: flex;
        gap: 20px;

        a{
            color: ${colors.primary_green};
            font-size: 12px;
            cursor: pointer;
        }

        a:hover{
            color: ${colors.secondary_white};
        }
    }

    .lang-switches{
        display: flex;
        gap: 20px;
    }

    .visible{
        transform: translateX(0);
    }

    @media screen and (max-width: 700px) {
        display: none;
        position: absolute;
        background-color: ${colors.primary_black};
        height: 100%;
        transform: translateX(100%);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 20px;
        opacity: 0.98;
        transition: all .5s ease-in-out;
        z-index: 5;

        .separator{
            display: none;
        }

        .square{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: auto;
            cursor: pointer;
            img{
                width: auto;
                height: 100%;
            }
        }

        .menu-bar{
            flex-direction: column;
            text-align: center;
            margin-bottom: auto;
            gap: 30px;

            a{
                font-size: 14px;
            }
        }

        .mobile{
            display: block;
        }

        .lang-switches{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
        }
}
`

const NavBar = ({ className, lang, type, handleMenu, setMenuVisible, menuVisible, ...props }) => {

    const navigate = useNavigate();

    const putNavBarItems = (lang) => {
        if (type == "home") {
            return false
        }
        var array = [];
        cms[lang].navbar.links.forEach((link, key) => {
            array.push(<a key={key} onClick={(e) => { handleMenu(link.link) }}>{link.title}</a>)
        });
        return array;
    }

    return (
        <NavContainer className={className}>
            <div className="square">
                <img onClick={() => {
                    navigate('/');
                }} src={cms[lang].navbar.logo} alt="" />
                <img className="mobile" onClick={() => { setMenuVisible(false) }} src="/assets/img/close.svg" alt="" />
            </div>
            <div className="menu-bar">
                {putNavBarItems(lang)}
                {type == 'home' ? null : <span className="separator">|</span>}
                <br className="mobile" />
                <span className="lang-switches">
                    <a onClick={() => { window.location.href = "https://ruidoneutro.com.br/" }} className="lang-switch">pt </a>
                    <a onClick={() => { window.location.href = "https://noiseneutral.com/" }} className="lang-switch"> en</a>
                </span>
            </div>
        </NavContainer>
    )
}

export default NavBar;