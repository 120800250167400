import React from "react";
import styled from "styled-components";
import colors from './utils/colors.json'
import cms from "./utils/copywriting.json"
import NNButton from "./NNButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import MotionBars from "./MotionBars";
import { useEffect } from "react";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${colors.primary_black};
    background-image: url('/assets/img/background.png');
    background-size: cover;
    justify-content: end;
    width: 100%;
    height: ${props => props.h + 'px'};
    box-sizing: border-box;
    padding: 50px;
    
 
        .cover-content{
            display: flex;
            box-sizing: border-box;
            margin-bottom: auto;
            margin-top: auto;

            .left, .right{
                display: flex;
            }
        }

        .header-title{
            font-weight: 400;
            letter-spacing: .15em;
            font-size: clamp(1.6rem, 2.5vw, 2.5rem);
            font-family: 'Moderat-Mono-Regular';
            margin: 0;
            margin-top: -50px;
            color: ${colors.primary_green};
            margin-bottom: 30px;
        } 
        
        .header-body-text{
            font-size: clamp(0.8rem, 1.1vw, 1.1rem);
            font-family: 'Anybody-Regular';
            color: ${colors.secondary_white};
            letter-spacing: .15em;
            margin: 0;
            line-height: 1.6em;
            margin-bottom: 30px;

            .subtext{
                color: ${colors.primary_gray};
                line-height: 1.5em;
                font-size: clamp(0.65rem, .9vw, 1.0rem);
            }
        }

        .logoNN{
            fill: ${colors.primary_green};
        }

        .brands-content{
            /* height: 200px; */
            margin-left: auto;
            width: auto;
            img{
                /* width: 70%; */
                max-width: 500px;
            }
        }    

    @media screen and (max-width: 3000px) and (min-width: 700px) {        
        .header-body-text{
            width: 55%;
        }
        
      
        .cover-content{
            flex-direction: row;
            justify-content: space-evenly;

            .left, .right{
                flex-direction: column;
            }
            
            .left{
                width: 60%;
                gap: 10px;
            }

            .right{
                width: 400px;
                gap: 20px;
                align-items: flex-end;

                .logo{
                    width: 60%;
                    height: auto;
                }

                img{
                    width: 100%;
                    height: auto;
                }
            }
        }

        .logoNN{
            width: 70%;
            height: 30%;
            margin: 20px 0;
        }

    }

    @media screen and (max-width: 700px) {
        padding: 20px;
        margin-top: 0px;
        
        .mobile{
            display: block;
        }

        .cover-content{
            flex-direction: column-reverse;
            gap: 20px;
        }

        .right{
           flex-direction: column;
           align-items: flex-start;
            gap: 15px;
            width: 60%;

           .logo{
            width: 50%;
           }

           img{
            width: 50%;
           }
        }

        .left{
           flex-direction: column;
           gap: 20px;
        }

        .header-body-text{
            width: 90%;
        }

        .header-title, .header-body-text{
            margin: 0px !important;
        }

        .plus-menu{
            width: 100%;
            display: flex !important;
            justify-content: end;
        }

    }

`

const Cover = ({ menuVisible, handleMenu, lang, setMenuVisible, ...props }) => {

    const [windowH, setWindowH] = useState(window.innerHeight);

    const navigate = useNavigate();

    window.addEventListener("resize", () => {
        setWindowH(window.innerHeight);
    })
    return (
        <Container h={windowH}>
            {/* <div className="mobile plus-menu">
                <img onClick={() => { setMenuVisible(true) }} src="/assets/img/plus.svg" alt="" />
            </div> */}

            <div className="cover-content">
                <div className="left">
                    <p className="header-title">
                        {cms[lang].cover.title}
                    </p>
                    <div className="header-body-text">
                        <p>{cms[lang].cover.paragraph[0]}</p>
                        <p className="subtext">{cms[lang].cover.paragraph[1]}</p>
                    </div>
                    {/* <NNButton onClick={() => { handleMenu('#problem') }} icon={"arrow"} label={cms[lang].cover.cta} type={'black'} /> */}
                    <NNButton onClick={() => { handleMenu('/calculate') }} label={cms[lang].cover.cta2} type={'black-3'} />
                </div>

                <div className="right">
                    <img className="logo" src={cms[lang].cover.logo} alt="" />
                    {/* <img src="/assets/logos/rastro.png" alt="" /> */}
                    <MotionBars timing={"infinite"} />
                </div>
            </div>

            {/* <div className="brands-content">
                <img src="/assets/img/logos_apoiadores.png" alt="" />
            </div> */}
            <div className="arrowScroll" onClick={() => {
                const element = document.querySelector(`#problem`);
                element.scrollIntoView({ behavior: 'smooth' });
            }}> <img src="/assets/img/arrow-scroll.png" alt="" /> </div>
        </Container>
    )
}

export default Cover;