import React, { useState } from 'react';
import styled from 'styled-components';
import colors from './utils/colors.json'
import cms from './utils/copywriting.json'
import useLanguageStore from "./useLanguageStore";
import { useRef } from "react";
import Swiper from 'react-id-swiper';
import 'swiper/swiper.min.css';
import { useNavigate } from "react-router-dom";

const Container = styled.div`
    display: ${props => props.active ? 'block' : 'none'};  
    position: relative;
    width: 50%;
    overflow-x: scroll;
    margin-top: ${props => props.active ? '0' : '60%'};
    z-index: 20;

    .carrossel-container{
        display: ${props => props.active ? 'flex' : 'none'};  
        overflow-x: scroll;
        scroll-behavior: smooth;
        align-items: flex-start;
        justify-content: start;
        width: fit-content; 
        
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .mobile{
        display: none;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        margin-top: 0;
        display: ${props => props.active ? 'block' : 'none !important'};
        padding: 0 20px;
    }
`;

const Card = styled.div`
    transition: all 0.5s ease-in-out;
    width: 300px;
    height: 500px;
    margin-right: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    cursor: grab;
    padding: 5px 20px;
    box-sizing: border-box;
    color: ${colors.primary_white};
    background-color: ${colors.terciary_gray};
    &:active {
        cursor: grabbing;
    }
   
    .card-top, .project-content{
        border-radius: 15px;            
        border: .1px solid ${colors.secondary_gray};
        font-family: 'Moderat-Mono-Regular';
    }

    .knowmore{
        color: ${colors.primary_green};
        font-size: clamp(.5rem, .9vw, .9rem);
        white-space: nowrap;
    }

    .project-text{
        max-width: 120px;
    }

    .ni_amount{
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
    }

    .duration{
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: end;
        width: 100%;
        margin: 10px 0;
        margin-left: auto;

        .duration-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            border-radius: 15px; 
            border: .1px solid ${colors.secondary_gray};
            font-family: 'Moderat-Mono-Regular';
            width: 40%;

            p{
                margin: 0;
                line-height: 30px;
                font-size: 16px;
            }
        }
    
        .duration-input{
            font-family: 'Moderat-Mono-Regular';
            background-color: transparent;
            border: none;
            color: ${colors.primary_white};
            width: 50%;
            /* border: 1px solid red; */
            font-size: 19px;

            ::-webkit-outer-spin-button,
            ::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            [type=number] {
                -moz-appearance: textfield;
            }
        }

        .duration-arrows {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            height: 35px;
        }

        .duration-arrows svg{
            height: 35px;
            box-sizing: border-box;
            padding: 5px;

            :active{
                transform: scale(.9);
            }
        }

        .duration-arrows svg path {
            fill: ${colors.primary_green};
        }
        
        .duration-arrows p{
            text-align: center;
            line-height: 1em;
            border: 1px solid white;
            width: 100%;
            font-family: '';
        }
    }

    .month{
        font-size: .35em;
    }

    .ni-icon{
        background-color: ${colors.primary_white};
        color: ${colors.secondary_black};
        font-size: clamp(2rem, 2.5vw, 2.5rem);
        padding: 8px;
        border-radius: 5px;
        text-shadow: .5px 0 ${colors.secondary_black}, -.5px 0 ${colors.secondary_black}, 0 .5px ${colors.secondary_black}, 0 -.5px ${colors.secondary_black},
               .5px .5px ${colors.secondary_black}, -.5px -.5px ${colors.secondary_black}, .5px -.5px ${colors.secondary_black}, -.5px .5px ${colors.secondary_black};
    }

    .card-top{
        font-family: "Anybody-Regular";
        display: flex;
        justify-content: space-between;
        color: ${colors.primary_green};
        margin: 10px 0;
        font-size: clamp(2rem, 2.5vw, 2.5rem);
        box-sizing: border-box;
        padding: 20px 10px;
    }

    .project-content{
        width: 100%;  
        padding: 10px;  
        box-sizing: border-box;
        margin-bottom: 10px;
        cursor: pointer;
        min-height: 300px;

        p{
            margin: 0;
        }
    }

    .project-content1{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: auto;
    }

    .project-cover{
        width: 100%;
        height: 200px;
        background-image: ${props => `url(${props.item.item_cover})`};
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        margin-bottom: 20px;
    }
    
    .title{
        font-family: 'NBLIGHTRMS';
    }

    .paragraph{
        font-family: 'Anybody-Regular';
        font-size: 11px;
        line-height: 1.5em;
    }

    .ni{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
        color: ${colors.primary_white};
        font-size: clamp(2.8rem, 2.3vw, 3rem);
        margin: 0;
    }

    .project-title{        
        font-size: 18px;
    }

    .open-card{
        width: 30px;
        height: 30px;
    }

    @media screen and (min-width: 1500px) {
        width: 350px;
        height: 600px;

        .project-cover{
            height: 250px;
        }

    }

    @media screen and (max-width: 600px) {
            padding: 15px 15px;
            border-radius: 15px;
            min-height: 450px;

        .duration-container{
            p{
                font-size: 14px;
            }
        }
    }
`;

const ProjectsContainer = ({ active, className }) => {
    var lang = useLanguageStore((state) => state.language);

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const containerRef = React.useRef(null);

    var qtd_projetos = 1;

    const switchCalcPerProjectType = (item) => {

        var personVol = 0;
        var densidadeDemografica = window.densidade;

        if (isNaN(densidadeDemografica)) {
            densidadeDemografica = item.person_vol;
        }

        if (item.item_type == "rodovia") {
            personVol = getPersonVol(1, densidadeDemografica, 60, 85, item.reduction_level, item.item_type);
        }

        if (item.item_type == "asfalto") {
            personVol = getPersonVol(1, densidadeDemografica, 60, 85, item.reduction_level, item.item_type);
        }

        if (item.item_type == "ferrovia") {
            personVol = getPersonVol(1, densidadeDemografica, 3, 95, item.reduction_level, item.item_type);
        }

        if (item.item_type == "default") {
            personVol = item.person_vol;
        }

        return personVol;
    }

    const handleDurationNumber = (id, key, indicator, item) => {
        if (window.innerWidth < 600) {
            qtd_projetos = Number(document.getElementsByClassName(`${id}`)[0].innerHTML);
        } else {
            qtd_projetos = Number(document.getElementsByClassName(`${id}`)[1].innerHTML);
        }

        if (indicator) {
            qtd_projetos = qtd_projetos + 1;
        } else {
            if (qtd_projetos > 1) {
                qtd_projetos = qtd_projetos - 1;
            }
        }

        var personVol = switchCalcPerProjectType(item);

        var vol_reduc_mensal = (item.action_time * item.reduction_level * item.action_days * personVol);
        vol_reduc_mensal = vol_reduc_mensal * qtd_projetos;
        var newDuration = Math.round((window.ni_total / vol_reduc_mensal));
        vol_reduc_mensal = Math.round(vol_reduc_mensal / window.divisor);


        if (window.innerWidth < 600) {
            document.getElementsByClassName(`${id}`)[0].innerHTML = qtd_projetos;
            document.getElementsByClassName(`ni_amount-${key}`)[0].innerHTML = Math.round(vol_reduc_mensal) * -1;
            document.getElementsByClassName(`month_amount-${key}`)[0].innerHTML = `${newDuration}/meses`;
        } else {
            document.getElementsByClassName(`${id}`)[1].innerHTML = qtd_projetos;
            document.getElementsByClassName(`ni_amount-${key}`)[1].innerHTML = Math.round(vol_reduc_mensal) * -1;
            document.getElementsByClassName(`month_amount-${key}`)[1].innerHTML = `${newDuration}/meses`;
        }
    }

    const getPersonVol = (rodoviaH, densidade, rodoviaW, rodoviadB) => {
        rodoviaW = rodoviaW / 2;
        var limitReduction = 30;
        var barrierMic = (rodoviadB); //- noiseBarrierdB
        var reductions = ((barrierMic - limitReduction) / 6);

        var areaW = rodoviaW;
        for (let i = 1; i < reductions; i++) {
            areaW = areaW * 2;
        }
        var area = areaW * rodoviaH;
        return area * densidade;
    }

    const getProjects = (lang) => {
        var array = [];
        cms[lang].galery.items.forEach((item, key) => {

            var personVol = switchCalcPerProjectType(item);

            var monthVol = window.monthSeletor;

            var vol_reduc_mensal = (item.action_time * item.reduction_level * item.action_days * personVol);

            var vol_emit_mensal = (window.ni_total / window.monthSeletor);

            qtd_projetos = Math.round((vol_emit_mensal / vol_reduc_mensal));

            if (qtd_projetos < 1) {
                qtd_projetos = 1;
                monthVol = 1;
            }

            vol_reduc_mensal = vol_reduc_mensal * qtd_projetos;
            monthVol = Math.round((window.ni_total / vol_reduc_mensal));
            vol_reduc_mensal = Math.round(vol_reduc_mensal / window.divisor);

            array.push(<Card key={key} item={item}>
                <div className="card-top">
                    <p className={`ni_amount title ni`}>
                        <span className={`ni_amount-${key} title ni`} >-{vol_reduc_mensal}</span>
                        <span className="ni-icon">ni</span>
                        <span className="month">{lang == 'pt' ? ' /mês' : ' /month'}</span>
                    </p>
                </div>
                <div className="duration">
                    <div className="duration-container">
                        <p className={`month_amount-${key}`}>{monthVol}  {lang == 'pt' ? '/meses' : '/months'}</p>
                    </div>
                    <div className="duration-container">
                        {/* <input id={`duration-${key}`} className="duration-input" type="number" /> */}
                        <p className={`duration-input duration-${key}`}>{qtd_projetos}</p>
                        <div className="duration-arrows">
                            <svg onClick={(e) => { handleDurationNumber(`duration-${key}`, key, true, item) }} data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.34 11.28">
                                <defs>
                                </defs>
                                <path d="M11,4.77H8.69c-.22,0-.36.29-.63.42L1.45,13.32a2.71,2.71,0,0,0-.79,2,.71.71,0,0,0,.79.74.69.69,0,0,0,.75-.74,1.87,1.87,0,0,1,.32-1L9,6.21h1.6l6.51,8.06a1.87,1.87,0,0,1,.32,1,.69.69,0,0,0,.76.74.7.7,0,0,0,.78-.74,3.39,3.39,0,0,0-.63-2l-6.7-8.13C11.49,5,11.36,4.77,11,4.77Z" transform="translate(-0.66 -4.77)" /></svg>


                            <svg onClick={(e) => { handleDurationNumber(`duration-${key}`, key, false, item) }} data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.34 11.28"><defs></defs><path className="cls-1" d="M8.67,16.05H11c.23,0,.36-.29.63-.42l6.61-8.14a2.71,2.71,0,0,0,.79-2,.7.7,0,0,0-.78-.73.69.69,0,0,0-.76.73,1.92,1.92,0,0,1-.32,1l-6.51,8.06H9L2.52,6.55a1.92,1.92,0,0,1-.32-1,.69.69,0,0,0-.75-.73.71.71,0,0,0-.79.73,3.39,3.39,0,0,0,.63,2L8,15.63C8.17,15.84,8.3,16.05,8.67,16.05Z" transform="translate(-0.66 -4.77)" /></svg>
                        </div>
                    </div>
                </div>

                <div className="project-content" id={key - 1} >
                    <div className="project-cover" id={key - 1} alt="" />
                    <div className="project-content1" id={key - 1} >
                        <p className="project-text" id={key - 1} >
                            <span id={key - 1} className="project-title title">{item.item_title}</span> <br />
                            <span id={key - 1} className="project-author paragraph">{item.item_author}</span>
                        </p>
                        <a href={`/project/${key}`} className="knowmore">+Saiba mais</a>
                    </div>
                </div>
            </Card>)
        })
        return array;
    }


    const handleMouseDown = (event) => {
        setIsDragging(true);
        setStartX(event.pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
    };

    const handleMouseMove = (event) => {
        if (!isDragging) return;
        event.preventDefault();
        const x = event.pageX - containerRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        containerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    return (
        <Container
            active={active} className={className}
            ref={containerRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
        >
            <div className='carrossel-container'>
                {getProjects(lang)}
            </div>
        </Container>
    );
};

export default ProjectsContainer;

